import { Box, Button, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { buyProductPrice, useActivePlan } from "module/PurchaseControl";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUserUid } from "useAuth";

const getPeriodicidadeStr = (price) => {
    if (price.billingPeriod) {
        if (price.billingPeriod === "P1M") return "por mês";
        if (price.billingPeriod === "P6M") return "a cada 6 meses";
        if (price.billingPeriod === "P1Y") return "por ano";
        return "ERROR";
    }

    if (price.intervalType) {
        if (price.intervalType === "month") {
            if (price.interval_count.toString() === "1") {
                return "por mês";
            }
            return "a cada " + price.interval_count + " meses";
        }

        if (price.intervalType === "year") {
            if (price.interval_count.toString() === "1") {
                return "por ano";
            }
            return "a cada " + price.interval_count + " anos";
        }
        return "ERROR";
    }

    return "";
};

const ProductView = ({ product }) => {
    // const location = useLocation();
    const [showPrecos, setShowPrecos] = useState(false);
    const priceToMap = [...product.prices];

    priceToMap.sort((a, b) => {
        return a.priceLong - b.priceLong;
    });

    const activePlan = useActivePlan();

    useEffect(() => {
        if (activePlan && (activePlan.uid || activePlan.paymentType)) {
            setShowPrecos(activePlan.uid === product.uid || activePlan.paymentType !== "manual" ? false : true);
        } else {
            setShowPrecos(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const cancelarAssinatura = (activePlan) => {
    //     // console.log("location", location);
    //     // buyProductPrice(price, userUid, "email", window.location.origin + "/" + location.pathname);
    //     cancelOrder(activePlan, window.location.origin + "/" + location.pathname);
    // };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "18px" }}>
            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "18px" }} sx={{ alignItems: "center" }}>
                {activePlan?.uid === product.uid && (
                    <>
                        <ProdutoCardPreco
                            price={activePlan}
                            product={activePlan}
                            disabled
                        />
                        <Button onClick={() => setShowPrecos(!showPrecos)}>
                            {showPrecos ? "Ocultar planos" : "Exibir planos"}
                        </Button>
                    </>
                )
                    // : activePlan.paymentType !== "manual" ? (
                    //     <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 20 }}>
                    //         {priceToMap.map((price, index) => (
                    //             <ProdutoCardPreco key={index} price={price} product={product} disabled={showPrecos} />
                    //         ))}
                    //     </Box>
                    // ) : null
                }

                {showPrecos && (
                    <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 20 }}>
                        {priceToMap.map((price, index) => (
                            <ProdutoCardPreco key={index} price={price} product={product} disabled={!showPrecos} />
                        ))}
                    </Box>
                )}
            </Box>

        </Box>
    );
};

export default function ListProducts({ products }) {
    return (
        <Box sx={{ height: "100%" }}>
            {products ? (
                products.map((p, i) => <ProductView key={i} product={p} />)
            ) : (
                <>
                    {products !== undefined && (
                        <Box>
                            <CircularProgress size={30} />
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
}

const ProdutoCardPreco = ({ price, product, disabled }) => {
    const [loading, setLoading] = useState(false);
    const userUid = useUserUid();
    const location = useLocation();
    const activePlan = useActivePlan()

    const buyPrice = () => {
        buyProductPrice(price, userUid, setLoading, window.location.origin + "#" + location.pathname);
    };
    const priceStr = (price.priceLong / 100).toFixed(2).replaceAll(".", ",");
    const periodicidadeStr = getPeriodicidadeStr(price);

    function getDiscountPercentage() {
        const divisor = price.intervalType === "month" ? price.interval_count : 12;

        // ensure that the month value is returning 
        const monthPriceLong = product?.prices?.filter((price) => price.intervalType === "month" && Number(price.interval_count) === 1)[0]

        // Math.round to round values
        // Math.abs to make positive
        let discount = Math.abs(Math.round(((price?.priceLong / 100 / divisor) * 100 / (monthPriceLong?.priceLong / 100)) - 100));

        return discount;
    }

    return (
        <Card sx={{ width: 280, maxWidth: 350, textAlign: "center", position: "relative" }}>
            {(periodicidadeStr === "a cada 6 meses" || periodicidadeStr === "por ano") &&
                <Box
                    sx={{
                        position: "absolute",
                        top: 20,
                        left: -10,
                        backgroundColor: "secondary.main",
                        color: "white",
                        transform: "translateX(-25%) translateY(-25%) rotate(-45deg)",
                        px: 6,
                        py: 0.5,
                        fontSize: "0.875rem",
                        fontWeight: "bold",
                        zIndex: 1,
                    }}
                >
                    {getDiscountPercentage() > 0 && `${getDiscountPercentage()}% OFF`}
                </Box>
            }
            <Box sx={{ backgroundColor: "primary.main", color: "white", p: 2 }}>
                <Typography variant="h5" component="h5">
                    {product.title}
                </Typography>
                <Typography variant="h4" component="h4">
                    R$ {priceStr}
                </Typography>
                <Typography variant="body2" color="white.600">
                    {periodicidadeStr}
                </Typography>
            </Box>
            <CardContent sx={{ width: "100%" }}>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    Inclui:
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    - Criação de Propriedades Ilimitadas
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    - Criação de Laudos Ilimitados
                </Typography>
                <Button sx={{ width: "100%" }} onClick={buyPrice} disabled={loading || disabled || activePlan?.priceUid === price?.uid}>
                    {loading ? "Adquirindo..." : activePlan?.priceUid === price?.uid ? "Plano Atual" : "Adquirir"}
                </Button>
            </CardContent>
        </Card>
    );
};
