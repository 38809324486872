import { Button, CircularProgress, Typography } from '@mui/material'
import firebaseUtil from 'firebaseUtil'
import React, { useEffect, useState } from 'react'

export default function SwitchDB({ closeMenu }) {
    const [loading, setLoading] = useState(true)
    const [online, setOnline] = useState(true)
    let networkDisabled = firebaseUtil.networkDisabled

    useEffect(() => {
        setOnline(!networkDisabled)
        setLoading(false)

    }, [networkDisabled])

    const toggleConection = () => {
        console.log('toggleConection')

        if (networkDisabled) {
            firebaseUtil.enableNetwork()
        } else {
            firebaseUtil.disableNetwork()
        }
        if (closeMenu) closeMenu()
    }

    return (
        // <div style={{ backgroundColor: online ? "#0F0" : "#f00", textAlign: 'center', padding: 5 }}>
        <div style={{ textAlign: 'center', padding: 5 }}>
            {online ? <Typography color="white">Banco de Dados Online</Typography> : <Typography color="white">Banco de Dados Offline</Typography>}
            <Button
                style={{ backgroundColor: online ? "#a30f0f" : "#008868" }}
                onClick={toggleConection}
                variant='contained'
                disabled={loading}
                color='primary'>
                {loading ? <CircularProgress size={18} /> : online ? "Trabalhar OFFLINE" : "Trabalhar ONLINE"}
            </Button>
        </div>
    )
}
