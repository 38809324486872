import { useAppContext } from "app/AppContext";
import firebaseUtil from "firebaseUtil";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePerfilContext } from "useAuth";
import RoutesMenusAreaCultivada from "./AreaCultivada";
// import RoutesAvisoHome from "./AvisoHome";
import RoutesMenusConvites from "./Convites";
import RoutesMenusCultura from "./Cultura";

import Home from "./Home";
import RoutesMenusInsumo from "./Insumo";
import RoutesMenusLaudoVisita from "./LaudoVisita";

import RoutesMenusPerfil from "./Perfil";
import RoutesMenusSupport from "./Support";
import { RoutesMenusPlanos } from "./Planos";
import RoutesMenusExclusaoDados from "./ExclusaoDados";


export default function RoutesMenusPrivate() {
    const navigate = useNavigate();
    const { setPerfil } = usePerfilContext()
    const { addMenu, addRoute, removeMenusRoutes } = useAppContext();
    // console.log('Private config')

    useEffect(() => {
        addMenu({
            uid: "Inicio",
            text: "Início",
            iconName: "Home",
            sequence: "0",
            action: () => {
                navigate("/");
            },
        });
        addRoute({ uid: "homeauth", title: "Início", route: "/", element: <Home /> });

        addMenu({
            uid: "sair",
            text: "Sair",
            iconName: "ExitToApp",
            sequence: "999999",
            action: async () => {

                await firebaseUtil.logout();
                localStorage.removeItem("hideDialogPlanWarning")
                setPerfil(null)
                navigate("/login");
            },
        });

        return () => {
            // console.log("remove buttoms private");
            removeMenusRoutes(["Inicio", "homeauth", "sair"]);
        };
    }, [addMenu, addRoute, navigate, removeMenusRoutes, setPerfil]);

    return (
        <>
            <RoutesMenusPerfil />
            <RoutesMenusConvites />
            <RoutesMenusAreaCultivada />
            <RoutesMenusLaudoVisita />
            <RoutesMenusInsumo />
            <RoutesMenusCultura />
            <RoutesMenusPlanos />
            <RoutesMenusExclusaoDados />
            {/* <RoutesAvisoHome /> */}
            <RoutesMenusSupport />
            {/* <RoutesMenusPoliticas /> */}
        </>
    );
}
