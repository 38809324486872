import Entity from "entity";
import { serverTimestamp } from "firebase/firestore";

export default class Perfil extends Entity {
    static get collectionUID() {
        return "User";
    }
    async initialize() { }

    static async onQuery(collection) {
        return collection;
    }

    static get labelField() {
        return "nome";
    }

    static get searchColumns() {
        return [
            { field: "uid", headerName: "ID", },
            { field: "nome", headerName: "Nome", flex: 2, filter: true, filterPosition: "1 1 1" },
            { field: "email", headerName: "E-mail", flex: 2, filter: true, filterPosition: "1 2 1" },
            { field: "empresa", headerName: "Empresa", flex: 1, filter: true, },
            { field: "tipo", headerName: "Tipo de Perfil", flex: 1, filter: true, },
            { field: "plataforma", headerName: "Plataforma" },
            {
                field: "lastLogin", headerName: "Último Login", filter: true,
                valueGetter: ({ value }) => {
                    if (value) {
                        let v = value.toDate().toISOString()
                        return v.substring(0, 16)
                    }
                    return "-"
                }
            },
            { field: "planoAtualNome", headerName: "Plano Contratado", filter: true, filterPosition: "1 4 1" },
            {
                field: "dataCriacao", headerName: "Primeiro Login", flex: 2,
                valueGetter: ({ value }) => {
                    if (value) {
                        let v = value.toDate().toISOString()
                        return v.substring(0, 16)
                    }
                    return "-"
                }
            },
            {
                field: "sincForcada", headerName: "Button Sync", flex: 1,
                valueGetter: ({ value }) => {
                    if (value) {
                        let v = value.toDate().toISOString()
                        return v.substring(0, 16)
                    }
                    return "-"
                }
            },

        ];
    }

    preSave(entity) {
        const perfil = entity.data

        if (perfil && perfil.planoContratado) {
            console.log("perfil.planoContratado ", perfil.planoContratado);

            if (!perfil.planoContratado.purchaseDate) {
                perfil.planoContratado.purchaseDate = serverTimestamp()
            }

            // precisei fazer essa condição para lidar com futuras alterações na data de contratação feita manualmente via perfil do admin
            // Ao alterar a data por lá, seu valor era alterado para o tipo yyyy-mm-dd e o componente de datas não sabe lidar com o valor
            // então fiz a conversão do valor para o tipo Date do js e salvei no objeto
            if (typeof perfil.planoContratado.purchaseDate === "string") {
                const dateParts = perfil.planoContratado.purchaseDate.split('-');
                const year = parseInt(dateParts[0], 10)
                const month = parseInt(dateParts[1], 10) - 1
                const day = parseInt(dateParts[2], 10)
                const dateObject = new Date(year, month, day)

                perfil.planoContratado.purchaseDate = dateObject
            }
        }
    }

    static getTokenConta(perfil) {
        if (perfil) {
            let token = perfil.uid;
            if (perfil.contaInternaDe) {
                token = perfil.contaInternaDe;
            }
            return token;
        } else {
            return null
        }
    }
}
