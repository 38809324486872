
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile, signInWithRedirect, sendPasswordResetEmail } from "firebase/auth";
import { initializeFirestore, CACHE_SIZE_UNLIMITED, persistentLocalCache, persistentMultipleTabManager, enableNetwork, disableNetwork } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';

import { signInWithRedirect as signInWithRedirectCordova, getRedirectResult as getRedirectResultCordova } from "firebase/auth/cordova";

export const config = {
	apiKey: "AIzaSyCbZ2HC8H4mytfP7DOehODRIbyG0MwJEww",
	authDomain: "elaudo-bf39d.firebaseapp.com",
	databaseURL: "https://elaudo-bf39d.firebaseio.com",
	projectId: "elaudo-bf39d",
	storageBucket: "elaudo-bf39d.appspot.com",
	messagingSenderId: "976426712558"
};
const app = initializeApp(config);
class FirebaseUtil {
	constructor() {
		// app.initializeApp(config)
		this.currentUser = null;
		this.perfilUser = null;

		if (window.cordova && window.cordova.plugins && window.cordova.plugins.firebase && window.cordova.plugins.firebase.auth) {
			console.log('auth cordova')
			this.auth = window.cordova.plugins.firebase.auth
		} else {
			this.auth = getAuth(app);
		}

		this.storageF = getStorage(app);

		this.db = initializeFirestore(app, {
			localCache: persistentLocalCache(/*settings*/{ cacheSizeBytes: CACHE_SIZE_UNLIMITED, tabManager: persistentMultipleTabManager() })

		});

		this.functions = getFunctions(app);

	}

	firestore() {
		return app.firestore
	}

	disableNetwork() {
		disableNetwork(this.db).then(() => {
			console.log("Network disabled!");
			this.networkDisabled = true;
		});
	}

	enableNetwork() {
		enableNetwork(this.db).then(() => {
			console.log("Network enabled!");
			this.networkDisabled = false;
		});
	}

	// networkStatus() {
	// 	if (this.networkDisabled)
	// 		return this.networkDisabled
	// }

	login(email, password) {
		return signInWithEmailAndPassword(this.auth, email, password)
	}


	logout() {
		this.currentUser = null;
		return this.auth.signOut()
	}

	async register(name, email, password) {

		await createUserWithEmailAndPassword(this.auth, email, password)
		// return this.getCurrentUser().updateProfile({
		// 	displayName: name
		// })

		return updateProfile(this.getCurrentUser(), {
			displayName: name
		})
	}


	isInitialized(updateUser) {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged((r) => {
				this.currentUser = r;
				if (updateUser) updateUser(r)
				resolve(r);
			})
		})
	}

	getCurrentUsername() {
		return this.getCurrentUser() && this.getCurrentUser().displayName
	}
	getCurrentUser() {
		if (this.currentUser) {
			return this.currentUser;
		}
		return this.auth.currentUser
	}

	async resetPassword(email) {
		console.log("email ", email);
		const auth = getAuth();
		return sendPasswordResetEmail(auth, email)
	}

	async createCount(email, password) {
		return new Promise((resolve, reject) => {
			let secondaryApp = app.initializeApp(config, "Secondary")

			secondaryApp.auth().createUserWithEmailAndPassword(email, password).then(
				(firebaseUser) => {
					// console.log('firebaseUser', firebaseUser)
					console.log("User created successfully!" /*+ firebaseUser.user.uid*/);
					secondaryApp.auth().signOut();
					resolve(firebaseUser)
				}).catch(error => {
					reject(error)
				})
		})
		// return this.getCurrentUser().updateProfile({ displayName: name })
	}
	loginUserWithGoogle() {
		if (window.cordova) {
			return new Promise((resolve, reject) => {
				const auth = getAuth();
				signInWithRedirectCordova(auth, new GoogleAuthProvider())
					.then(() => {
						return getRedirectResultCordova(auth);
					})
					.then((result) => {
						const credential = GoogleAuthProvider.credentialFromResult(result);
						console.log('credential', credential)
						// This gives you a Google Access Token.
						// You can use it to access the Google API.
						// const token = credential.accessToken;

						// The signed-in user info.
						// const user = result.user;
						resolve()
						// ...
					}).catch((error) => {
						// Handle Errors here.
						// const errorCode = error.code;
						// const errorMessage = error.message;
						reject(error)
					});
			})
		}
	}
	loginUserWithGoogle2() {
		return new Promise((resolve, reject) => {
			const provider = new GoogleAuthProvider();
			if (window.cordova) {
				signInWithRedirect(this.auth, provider)
					.then((result) => {
						// eslint-disable-next-line no-unused-vars
						const credential = GoogleAuthProvider.credentialFromResult(result)
						console.log('credential', credential)
						resolve()
					}).catch((error) => {
						console.log("error ", error);
						reject(error)
					})

			} else {
				const provider = new GoogleAuthProvider();
				signInWithPopup(this.auth, provider)
					.then((result) => {
						GoogleAuthProvider.credentialFromResult(result)
						resolve()

					}).catch((error) => {
						console.log("error ", error);
						reject(error)
					})

			}
		})
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FirebaseUtil()