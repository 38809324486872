//planoContratado
//trialDate

export function canSaveInForm(strKey, { perfil, activePlan }) {
    return new Promise((resolve, reject) => {
        if (perfil.tipo === "admin") {
            resolve(true);
        }

        if (!activePlan) {
            if (perfil.trialDate) {
                if (perfil.trialDate.isActive === false) {
                    reject("Seu período de testes acabou! Adquira o plano PRO acessando o menu 'Planos'.");
                }

                let today = new Date();
                let trialEndDate = new Date(perfil.trialDate.end);
                if (trialEndDate < today) {
                    reject("Seu período de testes acabou! Adquira o plano PRO acessando o menu 'Planos'.");
                }
            } else {
                reject("Sem plano trial.");
            }
        }

        resolve(true);
    });
}
