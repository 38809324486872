import { Box, Typography } from "@mui/material";

import ListProducts from "module/PurchaseControl/components/ListProducts";
import { useActivePlan, useProducts } from "module/PurchaseControl";

export const Planos = () => {
    const products = useProducts();
    const activePlan = useActivePlan();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ height: "100%" }}>
            {products && (
                <Box>
                    <Typography variant="h5" textAlign="center" p={2} fontWeight="bold">
                        {activePlan && !activePlan.trialDate && activePlan.paymentType !== "manual" ? "Plano Atual" : "Planos Disponíveis"}
                    </Typography>
                </Box>
            )}
            <ListProducts products={products} />
        </Box>
    );
};
