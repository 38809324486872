import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Entity from "entity";

import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { crateSnapDocument, salvarPerfilLogin, salvarPerfilRegistro } from "./dbApi";
import firebaseUtil from "./firebaseUtil";

import _ from "lodash";


export function useUserUid() {
    if (!firebaseUtil.getCurrentUser()) {
        return null;
    }
    return firebaseUtil.getCurrentUser().uid;
}
export const getUserSnap = (uidUser, resolve, reject) => {
    let colCustomForm = "User";
    return crateSnapDocument(
        uidUser,
        colCustomForm,
        (r) => {
            if (r.userUid && r.userUid !== r.uid) r.uid = r.userUid;
            resolve(r);
        },
        (err) => {
            console.log("getUserSnap erro", err);
            if (uidUser) {
                if (firebaseUtil.getCurrentUser()) {
                    salvarPerfilLogin(firebaseUtil.getCurrentUser());
                }
            }
            reject(err);
        }
    );
};

export const PerfilContext = React.createContext({});
function PerfilControl() {
    const [perfil, setPerfil] = useState(null);

    return { perfil, setPerfil };
}
export const PerfilProvider = ({ children }) => {
    const perfilAppContext = PerfilControl();
    const { setPerfil } = perfilAppContext;

    const useruid = useUserUid();

    useEffect(() => {
        let observer;
        if (useruid) {
            observer = getUserSnap(
                useruid,
                (r) => {
                    Entity.globalProps = { ...Entity.globalProps, perfil: r };
                    setPerfil((old) => {
                        if (
                            !_.isEqual(
                                { ...old, lastChange: null, lastLogin: null, updatePerfil: null },
                                { ...r, lastChange: null, lastLogin: null, updatePerfil: null }
                            )
                        ) {
                            return r;
                        }
                        return old;
                    });
                },
                (err) => {
                    setPerfil(null);
                }
            );
        } else {
            setPerfil(null);
        }
        return () => {
            if (observer) {
                observer();
            }
        };
    }, [setPerfil, useruid]);

    return <PerfilContext.Provider value={perfilAppContext}>{children}</PerfilContext.Provider>;
};

export const usePerfilContext = () => useContext(PerfilContext);

export const usePerfil = () => {
    const { perfil } = usePerfilContext();
    return perfil;
};

export function useIsAdmin() {
    let perfil = usePerfil();

    let isAdmin = false;

    if (perfil) {
        if (perfil.tipo === "admin") {
            isAdmin = true;
        }
    }
    return isAdmin;
}

export function useNotAuthenticatedOnly() {
    const { redirectPosAuth } = useAuth();

    useEffect(() => {
        if (firebaseUtil.getCurrentUser()) {
            redirectPosAuth();
        }
    }, [redirectPosAuth]);

    return null;
}

export function useAuthenticatedOnly() {
    const { redirectLogin } = useAuth();

    useEffect(() => {
        if (!firebaseUtil.getCurrentUser()) {
            redirectLogin();
        }
    }, [redirectLogin]);

    return null;
}
export default function useAuth() {
    const navigate = useNavigate();
    const currentUser = firebaseUtil.getCurrentUser();

    const redirectPosAuth = (pagePosLogin) => {
        navigate("/");
    };

    const redirectLogin = () => {
        navigate("/Login");
    };

    const redirectRegister = () => {
        navigate("/Register");
    };

    const isAuthenticated = useCallback(() => {
        if (!currentUser) {
            return false;
        } else {
            return true;
        }
    }, [currentUser]);

    const userName = () => {
        return firebaseUtil.getCurrentUsername();
    };

    const userUid = () => {
        if (!firebaseUtil.getCurrentUser()) {
            return null;
        }
        return firebaseUtil.getCurrentUser().uid;
    };

    const user = () => {
        return firebaseUtil.getCurrentUser();
    };

    const registerLogin = () => {
        // console.log('registerLogin', firebase.getCurrentUser())
        if (firebaseUtil.getCurrentUser()) {
            salvarPerfilLogin(firebaseUtil.getCurrentUser());
        }
    };

    const registerSignIn = () => {
        // console.log('registerSignIn', firebase.getCurrentUser())
        if (firebaseUtil.getCurrentUser()) {
            console.log("firebaseUtil.getCurrentUser() ", firebaseUtil.getCurrentUser());
            salvarPerfilRegistro(firebaseUtil.getCurrentUser());
        }
    };

    const loginUserWithGoogle = () => {
        if (window.cordova) {
            return firebaseUtil.loginUserWithGoogle();
        } else {
            const provider = new GoogleAuthProvider();
            const auth = getAuth()
            signInWithPopup(auth, provider)
                .then((result) => {
                    GoogleAuthProvider.credentialFromResult(result)
                    registerLogin()
                    redirectPosAuth()
                }).catch((error) => {
                    console.log("error ", error);
                })
            // redirectPosAuth();
        }
    };

    const loginUserWithApple = async () => {
        // if (window.cordova) {

        // } else {
        return new Promise((resolve, reject) => {
            const provider = new OAuthProvider("apple.com");
            const auth = getAuth()
            signInWithPopup(auth, provider)
                .then((result) => {
                    registerLogin()
                    redirectPosAuth()
                })
                .catch((error) => {
                    console.log("error", error);
                    reject(error);
                });
        });
        // }
    };

    return {
        redirectLogin,
        redirectRegister,
        redirectPosAuth,
        userName,
        userUid,
        user,
        registerLogin,
        registerSignIn,
        isAuthenticated,
        loginUserWithGoogle,
        loginUserWithApple,
    };
}

export function useInDebug() {
    const currentURL = window.location.href;
    const inDebug = currentURL.includes("localhost:") && !window.cordova;
    if (inDebug) {
        return true;
    } else {
        return false;
    }
}
