import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { usePerfil } from "useAuth";
import { useNavigate } from "react-router-dom";
import { dateToLocal } from "util/date";

export default function AlertaPeriodoTeste({ activePlan }) {
    const [showMessage, setShowMessage] = useState(false);

    const navigate = useNavigate();
    const hideDialogPlanWarning = window.localStorage.getItem("hideDialogPlanWarning")

    const perfil = usePerfil();
    useEffect(() => {
        if (perfil) {
            if (activePlan) {
                setShowMessage(true)
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfil]);

    const handleClose = () => {
        setShowMessage(false);
        window.localStorage.setItem("hideDialogPlanWarning", true);
    };

    const purchasePlan = () => {
        setShowMessage(false);
        navigate("/planos");
    };

    return (
        <>
            {showMessage && !hideDialogPlanWarning && (
                <Dialog
                    // fullScreen={fullScreen}
                    open={showMessage}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    {activePlan?.trialDate?.isActive ? (
                        <>
                            <DialogTitle id="responsive-dialog-title">Período de testes</DialogTitle>
                            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                <DialogContentText>
                                    Seu período de testes teve início no dia {dateToLocal(activePlan.trialDate.start)} e será finalizado no dia{" "}
                                    {dateToLocal(activePlan.trialDate.end)}.
                                </DialogContentText>
                                <DialogContentText>
                                    Após o período de testes você não será capaz de salvar nem criar novos laudos, sendo
                                    permitido apenas realizar a visualização e impressão.
                                </DialogContentText>
                                <DialogContentText style={{ fontSize: 14 }}>
                                    * Clique em "fechar" para não exibir novamente.
                                </DialogContentText>
                            </DialogContent>
                        </>
                    ) : (
                        <>
                            <DialogTitle id="responsive-dialog-title">Contrate um Plano</DialogTitle>
                            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                <DialogContentText>
                                    Você não será capaz de salvar nem criar novos laudos até contratar um plano, sendo
                                    permitido apenas realizar a visualização e impressão.
                                </DialogContentText>
                            </DialogContent>
                        </>
                    )}
                    <DialogActions sx={{ flexWrap: "wrap", padding: "0 25px 15px 25px", gap: "5px" }}>
                        <Button onClick={purchasePlan}>Adquirir plano Pro</Button>
                        <Button onClick={handleClose} color="error">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
