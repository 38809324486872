import Entity from "entity";
import AreaCultivada from "entity/AreaCultivada";
import ImageDB from "entity/ImageDB";
import Perfil from "entity/Perfil";

import { generateUid } from "functions";
import { useInDebug } from "useAuth";

import { clearAtributesExeto } from "util/object";
import LaudoVisitaReport from "./reports/LaudoVisitaReport";

import SelectInput from "components/form/input/SelectInput";

export default class LaudoVisita extends Entity {
    static get collectionUID() {
        return "Laudo";
    }

    async initialize() {
        this.data.plataforma = (window.cordova ? "MOBILE" : "WEB")
    }

    static async onQuery(collection) {
        let perfil = this.globalProps.perfil
        if (!perfil) {
            throw new Error(
                "this.globalProps deve receber perfil como globalProps"
            );
        }
        collection = collection.where("perfilDados", "==", Perfil.getTokenConta(perfil))

        return collection;
    }

    async onLoad() {
        this.data.fotos = await ImageDB.loadListFotosDoc(this.data.fotosKey);
        if (this.data.ocorrencia) {
            this.data.ocorrencia.fotos = await ImageDB.loadListFotosDoc(this.data.ocorrencia.fotosKey);
        }
    }

    async preSave(entity) {
        clearAtributesExeto(entity.data, "areaCultivada", ["uid", "nomeArea", 'nomeProdutor', 'unidadeProducao', 'unidadeArea'])

        if (!entity.data.fotosKey) {
            entity.data.fotosKey = generateUid()
        }

        await ImageDB.saveListFotosDoc(entity.data.fotosKey, entity.data.fotos);
        delete entity.data.fotos
        await ImageDB.deleteListFotosDoc(entity.data.fotosKey, entity.data.deleteFotos);
        delete entity.data.deleteFotos
        if (!entity.data.dataCadastro) {
            entity.data.dataCadastro = new Date()
        }

        if (entity.data.ocorrencia) {
            if (!entity.data.ocorrencia.fotosKey) {
                entity.data.ocorrencia.fotosKey = generateUid()
            }
            await ImageDB.saveListFotosDoc(entity.data.ocorrencia.fotosKey, entity.data.ocorrencia.fotos);
            delete entity.data.ocorrencia.fotos
            await ImageDB.deleteListFotosDoc(entity.data.ocorrencia.fotosKey, entity.data.deleteFotosOcorrencia);
            delete entity.data.deleteFotosOcorrencia
        }

        ///UPDATE AREA CULTIVADA AQUI

        let AreaCultivadaEntity = new AreaCultivada()
        AreaCultivadaEntity.update({
            uid: entity.data.areaCultivadaUid,
            dataUltimoLaudo: new Date(),
            fasesCultura: entity.data.fasesCultura || null,
            plantios: entity.data.plantios || [],
            dataPrevistaColheita: entity.data.dataPrevistaColheita || "",
            expectativaProdutividade: entity.data.expectativaProdutividade || "",
            porcentagemColhida: entity.data.porcentagemColhida || "",
            porcentagemPlantada: entity.data.porcentagemPlantada || "",
        });
        AreaCultivadaEntity.save().then(() => {
            console.log('ATUALIZOU AREA CULTIVADA')

        })
    }

    static async validFieldErrors(values) {
        console.log('values', values)
        return null
    }

    static get searchColumns() {
        return [
            { field: "dataCadastro", headerName: "Data", width: "110px", },
            { field: "sequencialIndex", headerName: "Nº", filter: true, width: "55px", mobileOrder: 2, },
            { field: "nomesCulturas", headerName: "Nome da Área", filter: true, mobileGridColumn: "1 / span 3", },
            { field: "stringProdutores", headerName: "Produtor", filter: true, mobileGridColumn: "1 / span 3", },
            { field: "areaTotalAreaCultivadas", headerName: "Área", filter: true, width: "90px", },
            { field: "safra", headerName: "Safra", filter: true, width: "100px", },
            { field: "cultura", headerName: "Cultura", filter: true, width: "120px", },
            { field: "stringLotes", headerName: "Talhões", filter: true, mobileGridColumn: "1 / span 2", mobileGridRow: '4' },
            {
                headerName: "Status",
                selector: row => row.cancelado ? 'Cancelado' : 'Emitido',
                filter: {
                    render: (props) => <SelectInput sx={{ width: '100%' }} name={'cancelado'} initialValue={false} label={"Mostrar cancelados?"} permiteLimpar toStringOptions={["Sim", "Não"]} options={[true, false]} />,
                    compare: (value, row, filterValues) => {
                        if (filterValues.cancelado) {
                            return row.cancelado
                        } else {
                            return !row.cancelado
                        }
                    }
                }
            },
        ];
    }

    static async preparePrint(laudo, perfil, areaCultivada) {
        this.create().then(entity =>
            entity.loadData(laudo).then(() => {
                new LaudoVisitaReport({ visita: entity.data, perfil, areaCultivada }).generate(useInDebug());

            })
        )
    }
}