import _ from "lodash";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { shallowDiff } from "util/object";
import {
    Timestamp,
    doc as cDodf,
    collection,
    getDoc,
    deleteDoc,
    onSnapshot,
    serverTimestamp,
    setDoc,
    updateDoc,
    doc,
} from "firebase/firestore";
import { getCollectionApi } from "dbApi";
import firebaseUtil from "firebaseUtil";
import { usePerfil } from "useAuth";

/**
 * Classe abstrata para uso de entidades da aplicação armazenadas no banco de dados.
 */
export default class Entity {
    static globalProps = {};
    constructor() {
        if (this.constructor === Entity) {
            throw new Error("Entity é uma classe abstrata e deve ser herdada");
        }

        /**
         * Coleção de dados.
         */
        this.data = {};

        /**
         * Armazena dados no momento do carregamento para detectar alterações no salvamento.
         */
        this.oldData = {};

        /**
         * Se o objeto ainda não foi salvo no banco de dados.
         */
        this.isNew = true;

        /**
         * Entidades relacionadas que serão removidas ao remover esta.
         */
        this.toDelete = [];

        /**
         * Callbacks chamados para notificar quando os dados são carregados.
         * Adicione um listener com o método 'subscribe(callback)'.
         */
        this.onLoadCallbacks = [];
    }

    //
    // Métodos abstratos.
    //

    /**
     * UID da collection no banco de dados.
     */
    static get collectionUID() {
        throw new Error("static  get collectionUID() deve ser definido com o UID da collection no banco de dados");
    }

    /**
     * Inicializa dados em um novo registro.
     */
    async initialize() { }

    /**
     * Executa transformações de dados após carregar.
     */
    async onLoad() { }

    /**
     * Executa transformações de dados antes de salvar.
     */
    async preSave(entity) { }

    /**
     * Executa transformações ao realizar uma consulta.
     */
    static async onQuery(collection) { }

    /**
     * Campo da collection que pode ser usado como rótulo do registro.
     */
    static get labelField() {
        throw new Error(
            "static get labelField() deve ser definido com um campo no banco de dados que representa o rótulo do objeto"
        );
    }

    /**
     * Colunas do MUI para campos de busca (relacionamentos).
     */
    static get searchColumns() {
        return [];
    }

    //
    // Fim dos métodos abstratos.
    //

    /**
     * Cria uma nova instância da coleção e executa método de inicialização de dados.
     */
    static async create(...args) {
        const instance = new this();
        instance.initialize(...args);

        return instance;
    }

    /**
     * Retorna a collection do Firebase para consultas.
     */
    static get collection() {
        return getCollectionApi(this.collectionUID);
    }

    /**
     * Retorna um doc do banco para interação com o objeto.
     *
     * Se o objeto for novo, gera um UID.
     */
    get doc() {
        let doc;
        if (this.data.uid) {
            doc = cDodf(firebaseUtil.db, this.constructor.collectionUID, this.data.uid);
        } else {
            doc = cDodf(collection(firebaseUtil.db, this.constructor.collectionUID));
        }
        this.data.uid = doc.id;

        return doc;
    }

    /**
     * Retorna um objeto do banco com o UID especificado.
     */
    static async get(uid) {
        const obj = new this();
        obj.isNew = false;
        obj.data.uid = uid;
        await obj.reload();

        return obj;
    }

    /**
     * Retorna um observer do banco para o UID especificado.
     */
    static onSnapshot(uid, callback) {
        const doc = this.collection.doc(uid);
        let observer = doc.onSnapshot(
            (docSnapshot) => {
                if (docSnapshot.exists) {
                    this.create().then((entity) =>
                        entity.loadData(docSnapshot.data()).then(() => {
                            callback(entity.data);
                        })
                    );
                    // callback(docSnapshot.data())
                } else {
                    console.log("onSnapshot not fond");
                }
            },
            (err) => {
                console.log("onSnapshot error:", err);
            }
        );
        return observer;
    }

    /**
     * Recarrega dados.
     */
    async reload() {
        if (this.data.uid) {
            console.info(`${this.constructor.name} load`);

            //const data = (await this.doc.get()).data() || {};
            const data = (await getDoc(this.doc)).data() || {};
            //await getDoc(docRef)
            this.loadData(data);
        }
    }

    async loadData(data) {
        this.data = data;
        // Armazena dados carregados para detectar alterações no salvamento.
        this.oldData = _.cloneDeep(this.data);

        await this.onLoad();
        this.notify();
    }

    /**
     * Registra um callback para ser chamado quando os dados forem recarregados na entidade.
     */
    subscribe(callback) {
        this.onLoadCallbacks.push(callback);
    }

    /**
     * Notifica os callbacks regitrados com 'subscribe(callback)' com os dados da entidade.
     */
    notify() {
        for (const callback of this.onLoadCallbacks) {
            callback(this.data);
        }
    }

    static async validFieldErrors(values) {
        return null;
    }

    /**
     * Salva objeto no banco de dados.
     */
    async save() {
        // Carrega o doc primeiro para garantir que o ID é gerado.
        const doc = this.doc;

        // Clona a entidade para não aplicar o preSave nos dados exibidos na tela,
        // e também permitir que o preSave funcione toda vez que for salvo.
        const saveEntity = _.cloneDeep(this);
        await this.preSave(saveEntity);

        // TODO: campo 'salvoOffline'

        // Realiza uma comparação para enviar apenas as alterações.
        let diff = shallowDiff(saveEntity.data, this.oldData);

        if (!_.isEmpty(diff)) {
            await this.updateLastChange(diff);
            console.info(`${this.constructor.name} save` + (this.isNew ? " new" : ""));

            return new Promise((resolve, reject) => {
                if (this.isNew) {
                    setDoc(doc, diff, { merge: true });
                    this.isNew = false;
                } else {
                    // Update permite remover objetos não presentes no diff, ao contrário do merge.
                    // doc.update(diff);
                    updateDoc(doc, diff);
                }

                this.deleteRelatedEntities();

                // Atualiza objeto ao salvar no banco.

                const disconnect = onSnapshot(doc, { includeMetadataChanges: true }, (snapshot) => {
                    this.loadData(snapshot.data());
                    if (!snapshot.metadata.fromCache) {
                        console.info(`${this.constructor.name} save online`);
                        // Salvo online.
                    }
                    disconnect();
                    resolve();
                });
            });
        }
    }

    /**
     * Realiza um merge nos dados do objeto.
     */
    update(data) {
        Object.assign(this.data, data);
        this.notify();
    }

    /**
     * Remove registro com o UID especificado.
     */
    static async delete(uid) {
        console.info(`${this.name} delete`);
        console.info(uid);

        let doc = cDodf(firebaseUtil.db, this.collectionUID, uid);

        deleteDoc(doc);

        // await this.collection.doc(uid).delete();
    }

    /**
     * Remove todas as entidades relacionadas marcadas para exclusão.
     */
    async deleteRelatedEntities() {
        for (const entity of this.toDelete) {
            await entity.Class.delete(entity.uid);
        }

        this.toDelete = [];
    }

    /**
     * Marca uma entidade para ser removida ao salvar esta.
     */
    deleteRelated(EntityClass, uid) {
        this.toDelete.push({
            Class: EntityClass,
            uid: uid,
        });
    }

    static async query() {
        const collection = this.collection;
        const q = await this.onQuery(collection);

        return q ? q : collection;
    }

    static async getAll() {
        return (await this.query()).get();
    }

    static async where(...args) {
        return (await this.query()).where(...args);
    }

    /**
     * Atualiza data e usuário que realizou a alteração no registro.
     */
    updateLastChange(data) {
        const now = new Date();

        // Data em que o salvamento foi enviado pelo usuário.
        data.dataLastChange = Timestamp.fromDate(now);
        data.dataLastChangeStr = format(now, "dd/MM/yyyy HH:mm");
        // Data em que o salvamento foi realizado no servidor.
        data.dataLastChangeServer = serverTimestamp();

        if (firebaseUtil.getCurrentUser()) {
            data.userLastChange = firebaseUtil.getCurrentUser().uid;
        }
    }

    static saveValuesEntity(values) {
        const entity = new this();
        entity.update({ ...values });
        return entity.save();
    }

    static getRefDoc(uid) {
        return this.collection.doc(uid);
    }

    static getRefDocMembro(docRef, uid) {
        const docs = doc(firebaseUtil.db, docRef, uid)
        return docs
    }
}

/**
 * Carrega um array com todos os registros de uma consulta.
 *
 * @param {*} query Promise de uma consulta (ex: collection.get())
 * @returns
 */
export async function fetchArray(query) {
    return new Promise((resolve, reject) => {
        const data = [];

        query.then((result) => {
            result.forEach((doc) => {
                data.push(doc.data());
            });

            resolve(data);
        });
    });
}

// /**
//  * Retorna um state React Hook com uma instância da classe de entidade especificada,
//  * para uso em componentes.
//  *
//  * Se o UID for especificado, carrega os dados do banco.
//  */
// export function useEntity(EntityClass, uid) {
//     const [entity, setEntity] = useState(new EntityClass());
//     const [, setData] = useState();

//     useEffect(() => {
//         // useEffect não aceita async diretamente.
//         async function load() {
//             let result;

//             if (uid) {
//                 // TODO: erro 404
//                 result = await EntityClass.get(uid);
//             } else {
//                 result = await EntityClass.create();
//             }

//             // Notifica render do componente ao recarregar dados.
//             result.subscribe(setData);

//             setEntity(result);
//         }

//         load();
//     }, [EntityClass, uid]);

//     return entity;
// }

// /**
//  * Retorna um state React Hook com uma instância da classe de entidade especificada,
//  * para uso em componentes.
//  *
//  * Se o UID for especificado, carrega os dados do banco.
//  */
// export function useEntityWithPredefinedValues(EntityClass, uid) {
//     const [entity, setEntity] = useState(new EntityClass());
//     const [, setData] = useState();

//     useEffect(() => {
//         // useEffect não aceita async diretamente.
//         async function load() {
//             let result;

//             if (uid) {
//                 // TODO: erro 404
//                 result = await EntityClass.get(uid);
//             } else {
//                 result = await EntityClass.create();
//             }

//             // Notifica render do componente ao recarregar dados.
//             result.subscribe(setData);

//             setEntity(result);
//         }

//         load();
//     }, [EntityClass, uid]);

//     const newEntityWithPredefinedValues = async predefinedValues => {
//         let newEntity = await EntityClass.create();

//         newEntity.data = { ...newEntity.data, ...predefinedValues }

//         // Notifica render do componente ao recarregar dados.
//         newEntity.subscribe(setData);

//         setEntity(newEntity);
//     }

//     return [entity, newEntityWithPredefinedValues];
// }

/**
 * useSnapList
 *
 * @param EntityClass Entidade de consulta
 * @param extraQuery promisse add querry
 * @returns
 */
export function useSnapListEntity(EntityClass, extraQuery) {
    const perfil = usePerfil();
    const [list, setList] = useState(null);

    useEffect(() => {
        let observer = null;
        const toAsync = (functioVar) => {
            let funcRetorno = functioVar;
            if (functioVar.constructor.name === "Function") {
                funcRetorno = (...args) => {
                    return new Promise((resolve) => resolve(functioVar(...args)));
                };
            }
            return funcRetorno;
        };
        if (perfil) {
            EntityClass.query().then((query) => {
                if (extraQuery) {
                    toAsync(extraQuery)(query).then((nq) => {
                        if (nq) {
                            observer = nq.onSnapshot((querySnapshot) => {
                                var data = [];
                                querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                });
                                setList(data);
                            });
                        }
                    });
                } else {
                    observer = query.onSnapshot((querySnapshot) => {
                        var data = [];
                        querySnapshot.forEach((doc) => {
                            data.push(doc.data());
                        });
                        setList(data);
                    });
                }
            });
        }
        return () => {
            if (observer) {
                observer();
            }
        };
    }, [EntityClass, extraQuery, perfil]);

    return [list];
}
/**
 * useSnapList
 *
 * @param EntityClass Entidade de consulta
 * @param extraQuery promisse add querry
 * @returns
 */
export function useGetListEntity(EntityClass, extraQuery) {
    const perfil = usePerfil();
    const [list, setList] = useState(null);

    useEffect(() => {
        if (perfil) {
            EntityClass.query().then((query) => {
                if (extraQuery) {
                    extraQuery(query).then((nq) => {
                        fetchArray(nq.get()).then((result) => {
                            setList(result);
                        });
                    });
                } else {
                    fetchArray(query.get()).then((result) => {
                        setList(result);
                    });
                }
            });
        }
    }, [EntityClass, extraQuery, perfil]);

    return [list];
}
