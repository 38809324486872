import { createContext, useContext, useEffect, useState } from "react";
import { buyProductPrice as buyProductPriceStripe, getProducts, getProductsOwned } from "./StripeFirestore";
import { usePerfil } from "useAuth";
import { saveOnlyNew } from "dbApi";
import { format } from "date-fns";

const productPlan = { android: "elaudo.assinatura.pro", ios: "elaudo.assinatura.pro", stripe: "" };

export function buyProductPrice(price, userUid, setLoading, urlReturn) {
    if (price.typePayment === "STRIPE") {
        buyProductPriceStripe(price, userUid, setLoading, urlReturn);
    }

    if (price.typePayment === "MOBILE") {
        console.log("buy mobile");
        console.log("price", price);
        console.log("userUid", userUid);

        console.log("urlReturn", urlReturn);
        price.original.order();
    }
}

export function cancelOrder(productOwned, urlReturn) {
    if (productOwned.typePayment === "STRIPE") {
        // buyProductPriceStripe(price, userUid, userEmail, urlReturn);
        window.location.assign(productOwned.original.stripeLink);
    }

    if (productOwned.typePayment === "MOBILE") {
        // console.log("buy mobile");
        // console.log("price", price);
        // console.log("userUid", userUid);
        // console.log("userEmail", userEmail);
        // console.log("urlReturn", urlReturn);
        // price.original.order();
    }
}

export const PurchaseControlContext = createContext({});

export const PurchaseControlProvider = ({ children }) => {
    const perfil = usePerfil();
    const perfilUid = perfil?.uid;
    const [products, setProducts] = useState(null);
    const [productsOwned, setProductsOwned] = useState(null);

    const [showDialog, setShowDialog] = useState(false);

    const [trialDate, setTrialDate] = useState({});

    const updateProduts = (newPurchase) => {
        if (window.cordova) {
            const adaptProduct = (products) => {
                return (products || []).map((p) => {
                    let productView = { prices: [] };
                    let date = new Date();
                    let seconds = date.getTime() / 1000;
                    productView.original = p;
                    productView.uid = p.id;
                    productView.title = productView.original.title;
                    productView.description = productView.original.description;
                    productView.owned = productView.original.owned;
                    productView.uidPrice = productView.original
                    productView.canPurchase = productView.original.canPurchase;
                    productView.purchaseDate = newPurchase ? { seconds } : ""
                    p.offers.forEach((oferta) => {
                        let pr = oferta.pricingPhases[0];
                        let billingPeriod = pr.billingPeriod;
                        let interval_count = billingPeriod.substring(1);
                        interval_count = interval_count.substring(0, interval_count.length - 1);

                        let intervalType = billingPeriod.endsWith("Y") ? "year" : "month";
                        let title = oferta.id === "elaudo.assinatura.pro@p1m" ? "e-Laudo Pro - Mensal" : oferta.id === "elaudo.assinatura.pro@pro-6m" ? "e-Laudo Pro - Semestral" : "e-Laudo Pro - Anual"
                        let priceView = {};

                        priceView.original = oferta;
                        priceView.uid = oferta.id;
                        priceView.title = title;

                        priceView.interval_count = interval_count;
                        priceView.intervalType = intervalType;

                        priceView.priceLong = pr.priceMicros / 10000;

                        priceView.typePayment = "MOBILE";

                        productView.prices.push(priceView);
                    });

                    return productView;
                });
            };
            const { store } = window.CdvPurchase;
            let listProducts = adaptProduct(store.products);
            let listProductsOwned = listProducts.filter((p) => p.owned);
            setProducts(listProducts);
            setProductsOwned(listProductsOwned);
        } else {
            if (perfilUid) {
                getProducts().then((p) => {
                    setProducts(p);
                    getProductsOwned(perfilUid, p).then((p1) => {
                        if (p1 && p1.length > 0) {
                            setProductsOwned(p1);
                        }
                    });
                });
            }
        }
    };
    const finishPurchase = (transaction) => {
        try {
            transaction.finish();
            console.log("Produto adquirido com sucesso!", transaction)
            updateProduts(true);
        } catch (error) {
            console.log("Erro na conclusão da compra: ", error);
        }
    };

    const checkForTrialDate = () => {
        // verificamos se ele tem plano ativo e também um trial definido, se sim, passamos o isActive do trial para falso
        if ((productsOwned && productsOwned.length > 0) && (perfil.trialDate && perfil.trialDate.isActive === true)) {
            console.log("Usuário com plano ativo e trial: desativando trial...")
            let planoAtual = {
                intervalType: productsOwned[0].intervalType ?? "",
                interval_count: productsOwned[0].interval_count ?? 0,
                typePayment: productsOwned[0].typePayment ?? "",
                uid: productsOwned[0].uid,
            }
            saveOnlyNew({ uid: perfil.uid, trialDate: { isActive: false, ...trialDate }, planoAtual, planoAtualNome: productsOwned[0].title ?? "e-Laudo Pro Mobile" }, "User");
            return
        }

        // checamos se tem plano ativo, se sim, pula essa verificação e não é necessário criar periodo de testes
        if (productsOwned && productsOwned.length > 0) {
            let planoAtual = {
                intervalType: productsOwned[0].intervalType ?? "",
                interval_count: productsOwned[0].interval_count ?? 0,
                typePayment: productsOwned[0].typePayment ?? "",
                purchaseDate: productsOwned[0]?.original?.created,
                uid: productsOwned[0].uid,
            }
            console.log("Usuário com plano ativo, atualizando perfil...")
            saveOnlyNew({ uid: perfil.uid, planoAtual, planoAtualNome: productsOwned[0].title ?? "e-Laudo Pro Mobile" }, "User");
            return
        }

        // Verificação do plano legacy
        if (perfil.planoContratado && perfil.trialDate) {
            console.log("Usuário com plano setado manualmente, atualizando perfil...")
            let planoAtual = {
                intervalType: "",
                interval_count: 0,
                typePayment: "manual",
                purchaseDate: perfil.planoContratado.purchaseDate,
                uid: "",
            }
            if (perfil.planoContratado.isActive === false) {
                saveOnlyNew({ uid: perfil.uid, trialDate: { isActive: false, ...trialDate }, planoAtual: { isActive: false, ...planoAtual }, planoAtualNome: "" }, "User");
            } else {
                saveOnlyNew({ uid: perfil.uid, trialDate: { isActive: false, ...trialDate }, planoAtual, planoAtualNome: perfil.planoContratado.name ?? "" }, "User");
            }
            return
        }

        // checamos se o usuário tem o trial date já ativo e verificamos se está no prazo
        if (perfil.trialDate) {
            let planoAtual = {
                intervalType: "",
                interval_count: 0,
                typePayment: "",
                purchaseDate: "",
                uid: "",
            }

            let today = new Date();
            let trialEndDate = new Date(perfil.trialDate.end);
            if (trialEndDate < today) {
                // caso não esteja no prazo, define o isActive para false
                console.log("Atualizando perfil: Trial expirado!")
                setShowDialog(true)
                saveOnlyNew({ uid: perfil.uid, trialDate: { isActive: false, ...trialDate }, planoAtual, planoAtualNome: "" }, "User");
                return
            } else {
                saveOnlyNew({ uid: perfil.uid, trialDate: { isActive: true, ...trialDate }, planoAtual, planoAtualNome: "" }, "User");
                setShowDialog(true)
                return
            }
        } else {
            // se não tiver plano, cria um novo de 30 dias
            let startDate = new Date();
            let endDate = new Date(startDate);
            endDate.setMonth(startDate.getMonth() + 1);
            let trialDate = { title: "Plano Trial", start: startDate.toISOString().split("T")[0], end: endDate.toISOString().split("T")[0], isActive: true };
            console.log("Criando trial...")
            saveOnlyNew({ uid: perfil.uid, trialDate }, "User");
            return
        }
    }

    useEffect(() => {
        if (perfil) {
            checkForTrialDate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsOwned])

    useEffect(() => {
        if (perfilUid) {
            checkForTrialDate()
            //startStore
            if (window.cordova) {
                const { store, ProductType, Platform } = window.CdvPurchase;
                // store.verbosity = store.DEBUG;
                if (window.cordova.platformId === "android") {
                    store.register([
                        {
                            id: productPlan.android,
                            type: ProductType.PAID_SUBSCRIPTION,
                            platform: Platform.GOOGLE_PLAY,
                        },
                    ]);
                    store.when().productUpdated(updateProduts).approved(finishPurchase);
                    store.initialize([Platform.GOOGLE_PLAY]);
                }
                if (window.cordova.platformId === "ios") {
                    store.register([
                        {
                            id: productPlan.ios,
                            type: ProductType.PAID_SUBSCRIPTION,
                            platform: Platform.APPLE_APPSTORE,
                        },
                    ]);
                    store.when().productUpdated(updateProduts).approved(finishPurchase);
                    store.initialize([Platform.APPLE_APPSTORE]);
                }
            } else {
                updateProduts();
            }
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfilUid]);

    return (
        <PurchaseControlContext.Provider
            value={{ products, productsOwned, showDialog, setShowDialog, trialDate, setTrialDate, perfil }}
        >
            {children}
        </PurchaseControlContext.Provider>
    );
};

export const usePurchaseControlContext = () => useContext(PurchaseControlContext);

export const useProducts = () => {
    const { products } = usePurchaseControlContext();
    return products;
};

export const useShowDialog = () => {
    const { showDialog } = usePurchaseControlContext();
    return showDialog;
};

export const useTrialDateVerifier = () => {
    const { trialDate, setTrialDate, perfil } = usePurchaseControlContext();

    if (perfil && perfil?.trialDate) {
        // verifica se está dentro do prazo
        let today = new Date();

        let start = perfil.trialDate.start.split("-").reverse().join("/");
        let end = perfil.trialDate.end.split("-").reverse().join("/");

        let endToCompare = new Date(perfil.trialDate.end);
        if (endToCompare < today) {
            setTrialDate({ start: start, end: end, isActive: false });
        }
        setTrialDate({ start, end, isActive: true });
        // trialActive(true)
    }

    return trialDate;
};

export const useActivePlan = () => {
    const perfil = usePerfil();
    const { productsOwned } = usePurchaseControlContext();

    if (!perfil) {
        return;
    }

    // handle para formatar a data antes de inserir no objeto
    // Padrão de data recebida: seconds
    // Padrão de data devolvida: "dd/mm/yyyy"
    function getDateFromActivePlan(dateInSeconds) {
        if (dateInSeconds) {
            const date = new Date(dateInSeconds * 1000)
            let formattedActivePlanStartDate = format(date, "dd/MM/yyyy")
            return formattedActivePlanStartDate
        }
        return ""
    }

    // unificar isso para retornar de uma forma globalizada

    if (productsOwned && productsOwned.length > 0) {
        let productOwned = {}
        productOwned.uid = productsOwned[0].uid
        productOwned.title = productsOwned[0]?.title
        productOwned.intervalType = productsOwned[0]?.intervalType
        productOwned.interval_count = productsOwned[0]?.interval_count
        productOwned.paymentType = productsOwned[0].typePayment
        productOwned.productUid = productsOwned[0].uid
        productOwned.priceUid = productsOwned[0]?.uidPrice
        productOwned.priceLong = productsOwned[0].priceLong
        productOwned.purchaseDate = window.cordova ? getDateFromActivePlan(productsOwned[0].purchaseDate.seconds) : getDateFromActivePlan(productsOwned[0]?.original?.items[0]?.created)
        return productOwned;
    }

    if (perfil.planoContratado) {
        let productOwned = {}

        if (perfil.planoContratado.isActive) {
            productOwned.uid = ""
            productOwned.title = perfil.planoContratado.name
            productOwned.intervalType = ""
            productOwned.interval_count = 0
            productOwned.paymentType = "manual"
            productOwned.productUid = ""
            productOwned.priceUid = ""
            productOwned.priceLong = 0
            productOwned.purchaseDate = getDateFromActivePlan(perfil.planoContratado.purchaseDate.seconds)
            return productOwned
        }
    }

    if (perfil?.trialDate) {
        if (perfil.trialDate.isActive) {
            let productOwned = {}
            let endDateToJsFormat = new Date(perfil.trialDate.end);
            let today = new Date();
            if (endDateToJsFormat < today) {
                return null
            } else {
                productOwned.trialDate = perfil.trialDate

                return productOwned
            }
        }
    }

    return null;
};

//actionsLimits
