import React from "react";
import { useAppContext } from "../../../app/AppContext";
import { Box, Typography, useMediaQuery } from "@mui/material";

import "./style.css";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import TelaInicialDash from "entity/AreaCultivada/components/Dashboards/TelaInicialDash";

import AccessControlHomeComp from "module/AccessControl/components/AccessControlHomeComp";

const im_Logo = require("./images/elaudo_logo.png");
const im_Novo = require("./images/novo.png");
const im_Consulta = require("./images/consulta.png");
// const im_fundo = require('./images/fundo.jpg');

export default function Home() {
    const theme = useTheme();
    let mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { useSetTitle } = useAppContext();

    useSetTitle("e-Laudo");

    return (
        <Box className="inicialContant">
            <AccessControlHomeComp />

            <div className="logoContant">
                <img className="logo" src={im_Logo} alt="Logo" />
                <Typography
                    style={{
                        fontSize: 48,
                        fontFamily: "Roboto",
                        fontWeight: "900",
                        color: "#028b76",
                    }}
                >
                    e-Laudo
                </Typography>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                    paddingBottom: 25,
                    paddingTop: 25,
                }}
            >
                <Link
                    to={"laudoVisita"}
                    style={{ textDecoration: "none", display: "flex", alignItems: "center", flexDirection: "column" }}
                >
                    <img style={{ width: mobile ? 100 : 120 }} src={im_Novo} alt="novoLaudo" />
                    <Typography
                        style={{
                            fontSize: mobile ? 18 : 24,
                            fontFamily: "Roboto",
                            fontWeight: "900",
                            color: "#008e6c",
                        }}
                    >
                        Novo Laudo
                    </Typography>
                </Link>

                <Link
                    to={"consulta-laudoVisita"}
                    style={{ textDecoration: "none", display: "flex", alignItems: "center", flexDirection: "column" }}
                >
                    <img style={{ width: mobile ? 100 : 120 }} src={im_Consulta} alt="consutaLaudos" />
                    <Typography
                        style={{
                            fontSize: mobile ? 18 : 24,
                            fontFamily: "Roboto",
                            fontWeight: "900",
                            color: "#008e6c",
                        }}
                    >
                        Consultar Laudos
                    </Typography>
                </Link>
            </div>
            <div style={{ flex: 1, width: "100%", marginBottom: 30 }}>
                <TelaInicialDash />
            </div>
        </Box>
    );
}
