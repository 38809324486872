import { Box, Typography } from "@mui/material";

import { useActivePlan, useShowDialog } from "module/PurchaseControl";
import AlertaPeriodoTeste from "../AlertaPeriodoTeste";

export default function AccessControlHomeComp() {
    const showDialog = useShowDialog()
    const activePlan = useActivePlan()

    const handleSupport = () => {
        window.open(
            "https://api.whatsapp.com/send/?phone=556798463002&text=Olá!%20Gostaria%20de%20ajuda%20sobre%20o%20e-Laudo.",
            "_blank"
        );
    };
    return (
        <>
            {showDialog && <AlertaPeriodoTeste activePlan={activePlan} />}
            <Box
                sx={{
                    background: "rgba(0,0,0,0.38)",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    cursor: "pointer",
                }}
                onClick={handleSupport}
            >
                <Typography textAlign="center" color="white">
                    <strong>O e-Laudo mudou!</strong>
                </Typography>
                <Typography textAlign="center" fontSize={14} color="white">
                    Caso tenha alguma dúvida, clique nesta mensagem para entrar em contato com o suporte.
                </Typography>
            </Box>
        </>
    );
}
