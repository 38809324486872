import { Box, Card, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import OkTable from 'components/OkTable'

import { LaunchSharp } from '@mui/icons-material'

export default function PainelProximosManejos({ laudos }) {
    const [mapManejoAreas, setMapManejoAreas] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        let mapAreas = {}
        const getArea = (area) => {
            const { uidArea } = area
            let dataMap = mapAreas[uidArea];
            if (!dataMap) {
                dataMap = { uidArea: uidArea, area, manejos: [] }
                mapAreas[uidArea] = dataMap
            }
            return dataMap
        }
        if (laudos) {
            laudos.forEach(l => {                
                // console.log('l', l)
                let manejos = _.get(l, "manejos.manejos")
                if (manejos) {
                    manejos = manejos.filter(m => m.dataPrevista)
                    let mapArea = getArea({ nome: l.nomesCulturas, uidArea: l.areaCultivadaUid, produtor: l.stringProdutores, cultura: l.cultura, safra: l.safra })
                    mapArea.manejos.push(...manejos.map(m => { return { ...m, laudo: l } }))
                    mapArea.manejos.sort((a, b) => a.dataPrevista.split('/').reverse() - b.dataPrevista.split('/').reverse())
                }

            });
            setMapManejoAreas(Object.values(mapAreas))
        }
    }, [laudos])

    return (
        <Card sx={{ m: 2, minHeight: 260, display: "flex", flex: '1 1 650px', flexDirection: 'column' }}>
            <Typography variant='h6' sx={{ backgroundColor: "#008e6c", p: 1 }}>Próximos Manejos</Typography>
            <Box sx={{ maxHeight: 300, display: "flex", flex: '1 1 450px', flexDirection: 'column', overflow: 'auto' }}>

                {mapManejoAreas.filter(m => m.manejos.length).map((m, i) => <Box key={i} sx={{ flex: 1, }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: "#008e6c", flexWrap: 'wrap', p: 1 }}>
                        <Typography variant='subtitle2' sx={{ flex: '1 1 330px' }}>Área: {m.area.nome}</Typography>
                        <Typography variant='subtitle2' sx={{ flex: 1 }}>{m.area.produtor}</Typography>
                        <Typography variant='subtitle2' >{m.area.cultura}-{m.area.safra}</Typography>
                    </Box>
                    <OkTable
                        data={m.manejos}
                        columns={[
                            {
                                name: "Data",
                                selector: (row) => {
                                    if (row.dataPrevista && row.dataPrevista.includes("-")) {
                                        row.dataPrevista = row.dataPrevista.split('-').reverse().join("/")
                                    } else {
                                        return _.get(row, "dataPrevista")
                                    }
                                    return _.get(row, 'dataPrevista')
                                },
                                width: "120px",
                            },
                            { name: "Talhão", field: "talhao.nome", mobileGridColumn: "1 / span 2" },
                            { name: "Área", field: "area", mobileGridRow: '2', mobileGridColumn: "3", width: "100px", },
                            { name: "Insumo", field: "mercadoria.descricao", },
                            { name: "Dose", field: "dose", },
                            { name: "QT", field: "quantidadeTotal", width: "100px", },
                            {
                                width: "65px",
                                mobileGridColumn: "3", mobileGridRow: '1',
                                cell: (row) => <IconButton onClick={(evt) => {
                                    if (evt.ctrlKey) {
                                        window.open('#/laudoVisita?uid=' + row.laudo.uid + "&tabMobile=Manejos", '_blank')
                                    } else {
                                        navigate('/laudoVisita?uid=' + row.laudo.uid + "&tabMobile=Manejos")
                                    }

                                }}><LaunchSharp /></IconButton>
                            }
                        ]}
                    />
                </Box>)}

            </Box>
        </Card>
    )
}
