import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Field } from 'react-final-form'

import { toast } from 'react-toastify';
import { motion } from "framer-motion"
import ImgLogo from './logo.png'

import { Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material'

import useAuth, { useNotAuthenticatedOnly } from 'useAuth'
import firebaseUtil from 'firebaseUtil'

// import GoogleAuth from 'routes/public/components/GoogleAuth';
// import AppleAuth from 'routes/public/components/AppleAuth';
import FormContent from 'components/form/FormContent';
import FormRow from 'components/form/FormRow';
import GoogleAuth from 'routes/public/components/GoogleAuth';
import AppleAuth from 'routes/public/components/AppleAuth';


const renderTextField = ({ input, label, meta: { touched, error }, valid, loginUsuario, handleSubmit, ...custom }) => (
    <TextField
        variant="standard"
        InputLabelProps={{
            shrink: true,
        }}
        style={{ marginBottom: 15 }}
        label={label}
        error={(touched && error) ? true : false}
        helperText={(touched && error) ? error : ""}
        onKeyDown={(e) => {
            if (e.keyCode === 13 && valid) {
                handleSubmit(loginUsuario)();
            }
        }}
        fullWidth

        {...input}
        {...custom}
    />
)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function LoginPage(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [errorMenssage,] = useState(null);

    const navigate = useNavigate();
    const { redirectPosAuth, registerLogin } = useAuth();

    useNotAuthenticatedOnly();

    const loginUsuario = async (dados) => {
        try {
            return new Promise((resolve, reject) => {
                firebaseUtil.login(dados.email, dados.senha)
                    .then((user) => {
                        registerLogin()
                        redirectPosAuth()
                        navigate("/")
                        resolve()
                    }).catch((error) => {
                        switch (error.code) {
                            case 'auth/invalid-email':
                                toast.error('Endereço de e-mail Inválido ❌')
                                break;
                            case 'auth/user-disabled':
                                toast.error('O usuário pode ter sido desativado 🔴')
                                break;
                            case 'auth/user-not-found':
                                toast.warning('Não há registro de usuário correspondente a esse identificador. O usuário pode ter sido excluído ⚠️')
                                break;
                            case 'auth/wrong-password':
                                toast.error('Senha inválida ou o usuário não tem uma senha 🔐')
                                break;
                            case 'auth/network-request-failed':
                                toast.info("Humm... Parece que você está sem conexão. Conecte-se a internet para entrar na sua conta 🛜", { autoClose: 4000 })
                                break
                            default:
                                break;
                        }
                    }).finally(() => {
                        resolve()
                    })
            })
        } catch (error) {
            console.log("error ", error);
        }
    }

    const recoverPassword = async (email) => {
        return new Promise((resolve, reject) => {
            firebaseUtil.resetPassword(email.emailToChangePassword)
                .then((data) => {
                    console.log("data ", data);
                    toast.success("E-mail de redefinição enviado!")
                    setOpen(false)
                    resolve()
                })
                .catch((err) => {
                    console.log("err ", err);
                    if (err.code === "auth/invalid-email") {
                        toast.error('Endereço de e-mail Inválido')
                    } else if (err.code === "auth/user-not-found") {
                        toast.error('Não há registro de usuário correspondente a esse identificador. O usuário pode ter sido excluído')
                    }
                    reject()
                })
        })
    }

    const handleContactSupport = () => {
        window.open(
            "https://api.whatsapp.com/send/?phone=556798463002&text=Olá!%20Gostaria%20de%20ajuda%20sobre%20a%20aplicação.",
            "_blank"
        )
    }

    return (
        <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0 }}
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: "25px",
                maxWidth: "364px",
                margin: "0 auto",
                overflow: "hidden"
            }}
        >
            <Form
                onSubmit={loginUsuario}
                render={({ handleSubmit, form, submitting, pristine, values, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', justifyContent: "center", padding: 25 }}>
                            <img src={ImgLogo} alt='logo' />
                        </div>
                        <Field name="email" label="Email" valid={valid} handleSubmit={handleSubmit} loginUsuario={loginUsuario} component={renderTextField} />
                        <Field name="senha" label="Senha" valid={valid} handleSubmit={handleSubmit} loginUsuario={loginUsuario} type="password" component={renderTextField} />
                        {errorMenssage && <Typography style={{ color: "red", lineHeight: 0, marginBottom: "15px", fontSize: "14px", fontWeight: "bold" }}> {errorMenssage}</Typography>}
                        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', flexDirection: 'column', gap: "15px" }}>
                            <Box style={{ width: 'fit-content', textAlign: "center", background: "transparent", border: "none", cursor: "pointer" }}
                                // component="button"
                                variant="body2"
                                color="secondary"
                                onClick={handleOpen}
                            >
                                <Typography color="primary" style={{ fontSize: 12 }}>Esqueci minha senha</Typography>
                            </Box>
                            <Button variant="contained" color="primary" style={{ height: "40px", width: '100%', textAlign: "center" }}
                                onClick={handleSubmit}
                                disabled={submitting}>
                                {!submitting && 'Login'}
                                {submitting && <CircularProgress size={20} />}
                            </Button>
                            {/* <GoogleAuth title="Entrar com Google" />
                            <AppleAuth title="Entrar com Apple" /> */}

                            <Typography
                                variant="caption"
                                fontWeight="bold"
                                textAlign="center"
                                sx={{ cursor: "pointer", border: "none", background: "transparent" }}
                                onClick={handleContactSupport}
                            >
                                Login com Google temporariamente desabilitado,
                                para mais informações entre em contato com o suporte
                                clicando nesta mensagem
                            </Typography>

                            <span>
                                Ainda não tem uma conta? <Link to='/signup' style={{ textDecoration: 'none', color: '#008868', fontWeight: 'bold' }}>Clique aqui.</Link>
                            </span>
                        </div>
                    </form>)}
            />

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography variant='h6' sx={{ padding: "0 0 1rem 0" }}>Redefinição de senha</Typography>
                    <Form
                        onSubmit={recoverPassword}
                        render={({ handleSubmit, form, submitting, pristine, values, valid }) => (
                            <form>
                                <FormContent>
                                    <FormRow>
                                        <Field name="emailToChangePassword" label="Digite o e-mail que deseja redefinir a senha" valid={valid} handleSubmit={handleSubmit} recoverPassword={recoverPassword} component={renderTextField} />
                                    </FormRow>
                                    <FormRow>
                                        <Button color='secondary' sx={{ flex: "1" }} onClick={handleClose}>Cancelar</Button>
                                        <Button onClick={handleSubmit}>Solicitar redefinição</Button>
                                    </FormRow>
                                </FormContent>
                            </form>
                        )}
                    />
                </Box>
            </Modal>
        </motion.div >
    )
}