import React, { useEffect, useState } from 'react'


import FormContent from 'components/form/FormContent'
import FormRow from 'components/form/FormRow'
import TextInput from 'components/form/input/TextInput'
import MapPoligons from '../../../../../../components/MapPoligons'
import { calcularAreaPolygonHA, poligonStr2List } from '../../../../../../components/MapPoligons/UtilCalcCordenadas'
import { useFieldValue } from 'components/form/input/NestedField'

export default function PainelTalhaoForm(props) {
  const { areaCultivada } = props
  const { nome } = props.values

  const [outrosPoligonos, setOutrosPoligonos] = useState([])
  const [poligonoStr] = useFieldValue('areaNoMapStr')

  useEffect(() => {
    if (areaCultivada.talhoes) {
      let ou = [];
      areaCultivada.talhoes.forEach(t => {
        if (t.nome !== nome) {
          if (t.areaNoMapStr) {

            ou.push({
              title: t.nome,
              props: { "Área": t.areaHa, "Cultura": areaCultivada.cultura },
              path: poligonStr2List(t.areaNoMapStr)
            })
          }
        }
      });
      setOutrosPoligonos(ou)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   console.log('poligonoStr', poligonoStr)
  //   if (poligonoStr) {

  //     let novaArea = calcularAreaPolygonHA(poligonStr2List(poligonoStr))
  //     if (novaArea !== null) {
  //       props.form.change("areaHa", novaArea.toFixed("2"))
  //     }
  //   }
  //   return () => { }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [poligonoStr])

  const updateArea = () => {
    console.log('poligonoStr', poligonoStr)
    if (poligonoStr) {
      let novaArea = calcularAreaPolygonHA(poligonStr2List(poligonoStr))
      if (novaArea !== null) {
        props.form.change("areaHa", novaArea.toFixed("2"))
      }
    }
  }


  return (<FormContent sx={{ p: 1 }}>
    <FormRow noWrap>
      <TextInput name="nome" label="Nome" required />
      <TextInput name="areaHa" type="number" label={"Área " + areaCultivada.unidadeArea} required />
    </FormRow>

    <MapPoligons name="areaNoMapStr" outrosPoligonos={outrosPoligonos} updatePoligono={updateArea} showCurrentLocation />


  </FormContent>
  )
}
