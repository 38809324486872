import React, { useState } from 'react'

import { Box, TextField, Typography } from '@mui/material';

import Perfil from 'entity/Perfil';

import DefaultEntityCrud from 'components/form/DefaultEntityCrud';
import FormContent from 'components/form/FormContent';
import FormRow from 'components/form/FormRow';
import TextInput from 'components/form/input/TextInput';
import TabContainer from 'components/tabs/TabContainer';
import TabPanel from 'components/tabs/TabPanel';
import SelectInput from 'components/form/input/SelectInput';
import ImageInput from 'components/form/input/ImageInput';
import DateInput from 'components/form/input/DateInput';

function PerfilFormInter(props) {
    const values = props.values
    console.log("values ", values);
    const [tab, setTab] = useState(0)

    return <FormContent sx={{}}>
        <TabContainer tab={tab} changeTab={setTab}>
            <TabPanel label="Dados" >
                <FormContent sx={{ m: 1 }}>
                    <div style={{ display: 'flex', flex: 1, padding: 5 }}>
                        <ImageInput name='logoEmpresa' label='Selecionar Logo' imageField="foto" txtSeletor="Selecionar" />
                    </div>
                    <FormRow>
                        <TextInput name="nome" label="Nome" />
                        <TextInput name="uid" label="UID" disabled />
                    </FormRow>
                    <FormRow>
                        <TextInput name="empresa" label="Empresa" />
                        <TextInput name="email" label="Email" disabled />
                    </FormRow>

                    <FormRow>
                        <TextInput name="crea" label="CREA" />
                        <TextInput name="telefone" label="Telefone" mask={"(00)[0]0000-0000"} />
                    </FormRow>
                    <FormRow>
                        <TextInput name="endereco_contato" label="Endereço" />
                    </FormRow>
                    <FormRow>
                        <SelectInput
                            name="tipo"
                            label="Tipo de Conta"
                            options={["comum", "admin", "equipe", "interna", "monitoramento"]}
                        />
                        <TextInput name="contaInternaDe" label="Token Conta Proprietária" />
                    </FormRow>
                    <Box sx={{ mt: "15px", border: values?.planoAtual?.typePayment === "STRIPE" && 1, borderRadius: 1, px: 2, py: 2, display: "flex", flexDirection: 'column', gap: 1 }}>
                        {values?.planoAtual?.typePayment === "STRIPE" ? (
                            <>
                                <Typography sx={{ textAlign: "center" }}>
                                    Essa assinatura foi realizada via <span style={{ color: "red", fontWeight: "bold" }}>Stripe</span>
                                </Typography>
                                <Typography sx={{ textAlign: "center" }}>
                                    Para gerenciar, acesse o {" "}
                                    <a
                                        style={{ textDecoration: "none", color: "gray" }}
                                        href='https://dashboard.stripe.com/dashboard'
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        Dashboard do Stripe
                                    </a>
                                </Typography>

                                <FormRow>
                                    <TextInput name="planoAtualNome" label="Plano contratado" disabled />
                                    <DateInput name="planoAtual.purchaseDate" label="Data de aquisição" disabled />
                                </FormRow>
                            </>
                        ) : values?.planoAtual ? (
                            <FormRow>
                                <TextInput name="planoContratado.name" label="Plano contratado" />
                                <SelectInput
                                    name="planoContratado.isActive"
                                    label="Status"
                                    toStringOptions={["Ativo", "Inativo"]}
                                    options={[true, false]}
                                />
                                <DateInput name="planoContratado.purchaseDate" label="Data de aquisição" />
                            </FormRow>
                        ) : values?.trialDate && values.trialDate.isActive ? (
                            <>
                                <Typography sx={{ color: "red", fontWeight: "bold" }}>Usuário com Plano Trial ativo</Typography>
                                <FormRow sx={{ border: 1, px: 2, py: 4, borderColor: "red", borderRadius: 2 }}>
                                    <TextField value="Plano Trial" label="Plano" variant='standard' sx={{ flex: 1 }} />
                                    <DateInput name="trialDate.start" label="Data de Início" />
                                    <DateInput name="trialDate.end" label="Data de Fim" />
                                </FormRow>
                                <FormRow>
                                    <TextInput name="planoContratado.name" label="Plano contratado" />
                                    <SelectInput
                                        name="planoContratado.isActive"
                                        label="Status"
                                        toStringOptions={["Ativo", "Inativo"]}
                                        options={[true, false]}
                                    />
                                    <DateInput name="planoContratado.purchaseDate" label="Data de aquisição" />
                                </FormRow>
                            </>
                        ) : null}
                    </Box>
                    <FormRow>
                    </FormRow>
                </FormContent >
            </TabPanel>
        </TabContainer>
    </FormContent >
}

export default function PerfilForm() {
    return (
        <DefaultEntityCrud
            EntityClass={Perfil}
            title={"Admin de Perfil"}
            routeForm="perfil"
            backRoute="consulta-perfil"
            formActions={{ novoForm: false, salvarForm: true, sairForm: true }}
        >
            {(props) => {
                return <PerfilFormInter {...props} />
            }}
        </DefaultEntityCrud>
    )
}