import { UploadFile } from '@mui/icons-material'
import { Button } from '@mui/material'

import { calcularAreaPolygonHA, poligonStr2List } from 'components/MapPoligons/UtilCalcCordenadas';
import { generateUid } from 'functions';
import useNotification from 'notification';
import React, { useCallback, useEffect, useState } from 'react'
import { xml2json } from 'xml-js';


export default function KmlImport({ talhoes, addTalhoes }) {
    const [talhoesInter, setTalhoesInter] = useState([])
    const [endProccess, setEndProccess] = useState()
    const [fileInput, setFileInput] = useState();

    const { showWarning } = useNotification()

    const fileInputRef = useCallback((node) => {
        if (node) {
            setFileInput(node);
        }
    }, []);

    useEffect(() => {
        if (talhoesInter.length && endProccess) {
            let nomes = (talhoes || []).map(t => t.nome)
            let realAdd = []
            talhoesInter.forEach(t => {
                let index = 0
                let nomeUsar = t.nome
                while (nomes.includes(t.nome)) {
                    index++
                    t.nome = nomeUsar + "(" + index + ")"
                }
                nomes.push(t.nome)
                realAdd.push(t)

            });
            addTalhoes(realAdd)
            setEndProccess(false)
            setTalhoesInter([])
        }


    }, [talhoesInter, endProccess, addTalhoes, talhoes, fileInput])

    const processaDataKml = (data) => {

        let document = data.Document;
        let talhoesKml = []

        const parseAddTalhao = (cords, nome) => {
            console.log("cords ", cords);
            console.log("nome ", nome);
            if (nome._text) {
                nome = nome._text
            } else {
                nome = nome._cdata
            }

            cords = cords.replace('/\n', " ");
            while (cords.indexOf("  ") !== -1) {
                cords = cords.replace("  ", " ");
            }
            var res = cords.split(" ");
            let cordenadas = "";
            res.forEach(local => {
                var item = local.split(",");
                cordenadas += item[1] + ":" + item[0] + ";"
            });
            let areaHa = calcularAreaPolygonHA(poligonStr2List(cordenadas))
            console.log("areaHa ", areaHa);
            if (areaHa !== null) {
                areaHa = areaHa.toFixed("2")
            }
            let t = { nome: nome, areaNoMapStr: cordenadas }
            if (areaHa) {
                t.areaHa = areaHa
            }
            t.uid = generateUid()

            talhoesKml.push(t)
        }

        if (document.Placemark) {
            if (document.Placemark.Polygon) {
                let cords = document.Placemark.Polygon.outerBoundaryIs.LinearRing.coordinates;
                parseAddTalhao(cords._text, document.Placemark.name ?? { _text: "T" });
            } else if (document.Placemark.MultiGeometry) {
                if (document.Placemark.MultiGeometry.Polygon.outerBoundaryIs) {
                    let cords = document.Placemark.MultiGeometry.Polygon.outerBoundaryIs.LinearRing.coordinates;
                    parseAddTalhao(cords._text, document.Placemark.name ?? { _text: "T" });
                }
                if (Array.isArray(document.Placemark.MultiGeometry.Polygon)) {
                    let pols = document.Placemark.MultiGeometry.Polygon
                    pols.forEach(p => {
                        let cords = p.outerBoundaryIs.LinearRing.coordinates;

                        parseAddTalhao(cords._text, { _text: "T" });
                    });
                }
            }
        } else {
            if (document.Folder) {
                if (document.Folder.Placemark) {
                    if (document.Folder.Placemark.LineString) {
                        let placemark = document.Folder.Placemark;
                        let coords = placemark.LineString.coordinates;
                        parseAddTalhao(coords._text, placemark.name ?? { _text: "T" });
                    } else {
                        document.Folder.Placemark.forEach(placemark => {
                            if (placemark.Polygon) {
                                let cords = placemark.Polygon.outerBoundaryIs.LinearRing.coordinates;
                                parseAddTalhao(cords._text, placemark.name ?? { _text: "T" });
                            } else if (placemark.MultiGeometry && placemark.MultiGeometry.Polygon.outerBoundaryIs) {
                                let cords = placemark.MultiGeometry.Polygon.outerBoundaryIs.LinearRing.coordinates;
                                parseAddTalhao(cords._text, placemark.name ?? { _text: "T" });
                            }
                        })
                    }
                }
            }
        }
        if (!talhoesKml.length) {
            showWarning("Nenhum talhão encontrado no arquivo")
        } else {
            // addTalhoes(talhoesKml)
            setTalhoesInter(old => (old || []).concat(talhoesKml))
        }
    }

    function onFileSelected(event) {
        setEndProccess(false)
        setTalhoesInter([])
        let processCont = 0
        if (event.target.files.length > 0) {
            for (let index = 0; index < event.target.files.length; index++) {
                const file = event.target.files[index];

                const reader = new FileReader();

                // eslint-disable-next-line no-loop-func
                reader.onload = (eventl) => {
                    let data = eventl.target.result
                    const json = JSON.parse(xml2json(data, { spaces: 2, compact: true, trim: true }));
                    if (json.kml) {
                        processaDataKml(json.kml)
                    } else {
                        showWarning("Arquivo Inválido!")
                    }
                    processCont++;

                    if (processCont === event.target.files.length) {
                        console.log('terminou', processCont) // quarto
                        setEndProccess(true)
                    }
                    event.target.value = ""
                }
                reader.readAsText(file);
            }
        }
    }

    function startSelectFile() {
        if (fileInput) {
            fileInput.click();
        }
    }

    let fileExtension = ".kml,.KML"

    if (window.cordova) {
        fileExtension = undefined
    }

    return (
        <>
            <Button onClick={startSelectFile}><UploadFile />KML</Button>
            <input
                ref={fileInputRef}
                hidden
                type="file"
                multiple={true}
                accept={fileExtension}
                onChange={(event) => onFileSelected(event)}
            />
        </>

    )
}
