import _ from "lodash";
import Report from "report";
import TableReportHelper from "report/helper";


export default class LaudoVisitaReport extends Report {
    constructor({ visita, perfil, areaCultivada }) {
        super();

        this.visita = visita;
        this.perfilAtual = perfil;
        this.areaCultivada = areaCultivada;
    }

    get title() {
        return /*this.perfilAtual.empresa ||*/ " ";
    }

    get fileName() {
        if (this.visita) {
            const sequencialIndex = _.get(this.visita, "sequencialIndex", "_");
            const nomeFazenda = _.get(this.visita, "areaCultivada.nomeArea").trim();

            return `Laudo nº ${sequencialIndex} - ${nomeFazenda}`;
        } else {
            return this.title;
        }
    }
    adapterMeta(meta) {
        // textoCabecalho1 = meta.textoCabecalho1
        // textoCabecalho2 = meta.textoCabecalho2
        // textoRodape1 = meta.textoRodape1 || meta.infoVersao
        // textoRodape2 = meta.textoRodape2
        //imageLogo = `data:image/png;base64,${meta.logo}`;

        if (this.perfilAtual.logoEmpresa) {
            meta.logo = this.perfilAtual.logoEmpresa.foto
        }

        let planoTxt = "PLANO GRATUITO - "

        if (this.perfilAtual.planoContratado && this.perfilAtual.planoContratado.isActive) {
            planoTxt = this.perfilAtual.planoContratado.name ?? "Plano PRO"
        }

        // if (!this.perfilAtual.planoContratado || (this.perfilAtual.planoContratado && this.perfilAtual.planoContratado.isActive === false)) {
        //     planoTxt = "PLANO GRATUITO - "
        // }

        meta.textoCabecalho1 = this.perfilAtual.empresa
        meta.textoCabecalho2 = this.perfilAtual.endereco_contato
        meta.textoCabecalho3 = "Laudo de Visita | e-Laudo"
        meta.textoRodape2 = planoTxt + ' - e-Laudo - www.elaudo.agr.br'
        return meta
    }

    async render() {
        this.helper = new TableReportHelper(this.visita);

        return [
            await this.renderHeader(),
            await this.renderCaracteristicas(),
            await this.renderExpectativas(),
            await this.renderManejos(),
            await this.renderCaldas(),
            await this.renderOcorencia(),
            await this.renderControlePragas(),
            await this.renderFotos(),
            await this.renderPlantio(),
            await this.renderColheita(),
            await this.renderParecer(),
        ]
    }

    renderHeader() {
        const h = this.helper;

        const content = []
        content.push(
            h.panel([
                h.row(h.title('Técnico'), h.textField('userNome'), h.title('Nº'), h.textField('sequencialIndex')),
            ], { widths: [60, "*", 18, 60] })
        )

        content.push(
            h.fieldset("IDENTIFICAÇÃO", [

                h.panel([
                    h.row(h.title('Produtor'), h.textField('stringProdutores')),
                    h.row(h.title('Propriedade'), h.textField('nomesCulturas')),
                    h.row(h.title('Talhões'), h.textField('stringLotes')),
                ], { widths: [75, "*"] }),
                h.panel([
                    h.row(h.title('Cultura'), h.textField('cultura'), h.title('Safra'), h.textField('safra')),
                    h.row(h.title('Área Total'), h.textField('areaTotalAreaCultivadas'), h.title('Área do Laudo'), h.textField('areaTotalLaudo')),
                ], { widths: [75, "*", 70, "*"] })
            ]
            )
        )


        return content;
    }



    renderCaracteristicas() {
        const h = this.helper;

        const content = []
        let fases = [];

        (_.get(this.visita, "fasesCultura.itensFase") || []).forEach(f => {
            if (f.area && parseFloat(f.area) > 0) {
                fases.push(h.panel([
                    h.row(h.title(f.nome, { colSpan: 2, alignment: "center", }),),
                    h.row(h.title('%', { alignment: "center", }), h.title('Área', { alignment: "center", })),
                    h.row(h.text(f.porcentagem, { alignment: "center", }), h.text(f.area, { alignment: "center", })),
                ], { widths: ["*", "*"] }),)
            }
        })

        if (!fases.length && !_.get(this.visita, "porcentagemPlantada") && !_.get(this.visita, "porcentagemColhida")) {
            return null
        }
        content.push(
            h.fieldset("CARACTERÍSTICAS DA LAVOURA", [
                h.panel([
                    h.row(h.title('Área Plantada (%)'), h.textField('porcentagemPlantada'), h.title('Área Colhida (%)'), h.textField('porcentagemColhida')),
                ], { widths: [75, "*", 70, "*"] }),
                fases.length ? h.panel([
                    h.row(h.title("Fases da Cultura"),),
                ]) : null,
                fases.length ? h.panel([
                    h.row(...fases),
                ]) : null,
            ])
        )


        return content;
    }


    renderExpectativas() {
        const h = this.helper;
        const content = []
        if (!_.get(this.visita, "dataPrevisaoColheita") && !_.get(this.visita, "expectativaProdutividade")) {
            return null;
        }
        content.push(
            h.unbreakable(
                h.fieldset("EXPECTATIVAS DA LAVOURA", [
                    h.panel([
                        h.row(h.title('Previsão de Colheita'), h.textField('dataPrevisaoColheita', { type: "date" })),
                        h.row(h.title('Estimativa de Produtividade (' + this.areaCultivada.unidadeProducao + "/" + this.areaCultivada.unidadeArea + ')'), h.textField('expectativaProdutividade')),
                    ]),
                ]))
        )
        return content;
    }

    renderManejos() {
        const h = this.helper;

        const content = []
        let manejos = _.get(this.visita, "manejos.manejos")
        if (!manejos || !manejos.length) {
            return null;
        }
        let grouped_data = _.groupBy(manejos, m => m.talhao.nome)

        let cont = []

        for (const nomeTalhao in grouped_data) {
            if (Object.hasOwnProperty.call(grouped_data, nomeTalhao)) {
                const manejosTalhao = grouped_data[nomeTalhao];

                cont.push(h.panel([
                    h.row(h.title("Talhão: " + nomeTalhao, { fontSize: 12 })),
                ]))
                let addCols = []
                _.find(manejosTalhao, { 'age': 1, 'active': true });
                // if(manejosTalhao)
                if (manejosTalhao.filter(m => m.vazao).length) {

                    addCols.push({ title: "Vazão", field: "vazao", width: 'auto' })
                }
                if (manejosTalhao.filter(m => m.dosePorTanque).length) {
                    addCols.push({ title: "Dose por Tanque", field: "dosePorTanque", width: 'auto' })
                }
                if (manejosTalhao.filter(m => m.volumeTanque).length) {
                    addCols.push({ title: "Volume Tanque (L)", field: "volumeTanque", width: 'auto' })
                }

                if (manejosTalhao.filter(m => m.valorUnitario).length) {
                    addCols.push({ title: "Valor Unitário", field: "valorUnitario", width: 'auto' })
                    addCols.push({ title: "Valor Total", field: "valorTotal", width: 'auto' })
                }
                cont.push(h.tablePanel([
                    { title: "Insumo", getValue: r => (_.get(r, "mercadoria.descricao") + " - " + _.get(r, "mercadoria.unidadeMedida.sigla")) },

                    { title: "Área (" + this.areaCultivada.unidadeArea + ")", field: "area", width: 'auto' },
                    { title: "Dose (/" + this.areaCultivada.unidadeArea + ")", field: "dose", width: 'auto' },
                    { title: "QT Total", field: "quantidadeTotal", width: 'auto' },


                    ...addCols,

                    { title: "Realizado", getValue: r => r.executado ? "Sim" : "Não", width: 'auto' },
                ], manejosTalhao),)

            }
        }

        content.push(
            h.fieldset("RECOMENDAÇÕES - MANEJOS",
                cont
            )
        )


        return content;
    }

    renderCaldas() {
        const h = this.helper;

        const content = []
        let caldas = _.get(this.visita, "caldas")
        if (!caldas || !caldas.length) {
            return null;
        }

        let grouped_data = _.groupBy(caldas, values => {
            let concatedTalhoesName = ""
            values.talhoes.forEach((talhao) => {
                concatedTalhoesName += talhao.nome + "; "
            })
            return concatedTalhoesName
        })

        let cont = []

        for (const nomeTalhao in grouped_data) {
            if (Object.hasOwnProperty.call(grouped_data, nomeTalhao)) {
                const caldasTalhao = grouped_data[nomeTalhao];
                const insumosCalda = []
                caldasTalhao.forEach((calda) => {
                    calda.insumos.forEach(insumo => {
                        insumosCalda.push(insumo)
                    })
                })

                cont.push(h.panel([
                    h.row(h.title("Talhões: " + nomeTalhao, { fontSize: 12, marginTop: 5 })),
                ]))
                cont.push(h.tablePanel([
                    { title: "Área Total (" + this.areaCultivada.unidadeArea + ")", field: "areaAplicacao", width: '15%' },
                    { title: "Volume Tanque (L)", field: "volumeTanque", width: '17%' },
                    { title: "Vazão (L/ha)", field: "vazao", width: '17%' },
                    { title: "Quantidade Tanque (ha)", field: "areaTanque", width: '20%' },
                    { title: "Data Prevista", field: "dataPrevista", width: '17%' },
                    { title: "Realizado", getValue: r => r.executado ? "Sim" : "Não", width: '14%' },
                ], caldasTalhao),)

                cont.push(h.panel([
                    h.row(h.title("Aplicação", { fontSize: 12 })),
                ]))

                cont.push(h.tablePanel([
                    { title: "Insumo", field: "descricao", width: '*' },
                    { title: "Dose (ha)", field: "aplicacao.doseHa", width: 85 },
                    { title: "Dose Tanque", field: "aplicacao.doseTanque", width: 85 },
                ], insumosCalda))

                cont.push(h.tablePanel([
                    { title: "Observação de Aplicação", field: "observacao", }
                ], caldasTalhao))

                cont.push(h.row([
                ]))
                cont.push(h.row([
                ]))
            }
        }

        content.push(
            h.fieldset("RECOMENDAÇÕES - CALDAS",
                cont
            )
        )
        return content;
    }

    renderOcorencia() {

        const h = this.helper;
        const content = []
        let contFotos = []
        if (!this.visita.ocorrencia) {
            return null
        }
        let fotos = this.visita.ocorrencia.fotos



        fotos.forEach(f => {
            let addRows = []
            if (f.pontoGeo) {
                addRows.push(h.row(h.text('foto'), h.location({ lat: f.pontoGeo.latitude, lng: f.pontoGeo.longitude }),))
            }


            contFotos.push(h.row(h.panel([
                h.row(h.image(f.foto.startsWith('data') ? f.foto : "data:image/jpeg;base64," + f.foto, { fit: [250, 250], rowSpan: 2 + addRows.length, alignment: 'center' }), h.title('Descrição'),),
                h.row(h.text('foto'), h.text(_.get(f, 'descricao')),),
                ...addRows
            ], { widths: ["*", "*"] }),))
        });

        content.push(

            h.fieldset("OCORRÊNCIA", [
                h.unbreakable(
                    h.panel([
                        h.row(h.title('Nome'), h.textField('ocorrencia.nome')),
                        h.row(h.title('Tipo'), h.textField('ocorrencia.tipo')),
                        h.row(h.title('Nivel'), h.textField('ocorrencia.nivel')),
                        h.row(h.title('Observação', { colSpan: 2, }),),
                        h.row(h.textField('ocorrencia.observacao', { colSpan: 2, })),
                    ])),
                h.panel(contFotos),
            ])
        )

        return content;
    }


    renderControlePragas() {
        const h = this.helper;
        const content = []
        let cont = []
        if (_.get(this.visita, "questionario.manejoPlantasDaninhas")) {
            cont.push(h.row(h.title('Manejo de Plantas Daninhas')))
            cont.push(h.row(h.textField("questionario.manejoPlantasDaninhas")))
        }
        if (_.get(this.visita, "questionario.manejoPragas")) {
            cont.push(h.row(h.title('Manejo de Pragas')))
            cont.push(h.row(h.textField("questionario.manejoPragas")))
        }
        if (_.get(this.visita, "questionario.manejoDoencas")) {
            cont.push(h.row(h.title('Manejo de Doenças')))
            cont.push(h.row(h.textField("questionario.manejoDoencas")))
        }
        if (_.get(this.visita, "questionario.outrasInformacoes")) {
            cont.push(h.row(h.title('Outras Informações')))
            cont.push(h.row(h.textField("questionario.outrasInformacoes")))
        }
        if (!cont.length) {
            return null;
        }
        content.push(
            h.unbreakable(
                h.fieldset("CONTROLE DE PRAGAS", [
                    h.panel(cont),
                ])
            )
        )
        return content;
    }

    renderFotos() {

        const h = this.helper;
        const content = []
        let cont = []

        let fotos = this.visita.fotos
        if (!fotos || !fotos.length) {
            return null
        }


        fotos.forEach(f => {
            let addRows = []
            if (f.pontoGeo) {

                addRows.push(h.row(h.text('foto'), h.location({ lat: f.pontoGeo.latitude, lng: f.pontoGeo.longitude }),))
            }

            cont.push(h.row(h.panel([
                h.row(h.image(f.foto.startsWith('data') ? f.foto : "data:image/jpeg;base64," + f.foto, { fit: [250, 250], rowSpan: 4 + addRows.length, alignment: 'center' }), h.title('Talhão'),),
                h.row(h.text('foto'), h.text(_.get(f, 'talhao.nome')),),
                h.row(h.text('foto'), h.title('Descrição'),),
                h.row(h.text('foto'), h.text(_.get(f, 'descricao')),),
                ...addRows
            ], { widths: ["*", "*"] }),))
        });
        if (fotos.length === 1) {
            content.push(
                h.unbreakable(
                    h.fieldset("FOTOS", [
                        h.panel(cont),
                    ])
                )
            )
        } else {

            content.push(
                h.fieldset("FOTOS", [
                    h.panel(cont),
                ])
            )
        }
        return content;
    }
    renderPlantio() {
        const h = this.helper;
        let fontSizeUsar = 7
        const content = []
        let plantios = _.get(this.visita, "plantios")
        if (!plantios || !plantios.length) {
            return null;
        }
        let grouped_data = _.groupBy(plantios, m => m.talhao.nome + " - Área: " + m.talhao.areaHa + " " + this.areaCultivada.unidadeArea)

        let cont = []

        for (const nomeTalhao in grouped_data) {
            if (Object.hasOwnProperty.call(grouped_data, nomeTalhao)) {
                const plantioTalhao = grouped_data[nomeTalhao];

                cont.push(h.panel([
                    h.row(h.title("Talhão:" + nomeTalhao, { fontSize: 12 })),
                ]))
                let addCols = []

                // addCols.push({ title: "Vazão", field: "vazao", width: 'auto' })




                if (plantioTalhao.filter(m => m.numeroGraos).length) {

                    addCols.push({ title: "N° Grãos", field: "numeroGraos", width: 'auto', propsField: { fontSize: fontSizeUsar } })
                }

                if (plantioTalhao.filter(m => m.numeroGraos).length) {
                    addCols.push({ title: "N° Vagens", field: "numeroVagens", width: 'auto', propsField: { fontSize: fontSizeUsar } })
                }

                if (plantioTalhao.filter(m => m.numeroNo).length) {
                    addCols.push({ title: "N° Nó", field: "numeroNo", width: 'auto', propsField: { fontSize: fontSizeUsar } })
                }


                if (plantioTalhao.filter(m => m.alturaPlanta).length) {
                    addCols.push({ title: "Altura Planta(CM)", field: "alturaPlanta", width: 'auto', propsField: { fontSize: fontSizeUsar } })
                }

                if (plantioTalhao.filter(m => m.sementeMetro).length) {
                    addCols.push({ title: "Sementes p/ Metro", field: "sementeMetro", width: 'auto', propsField: { fontSize: fontSizeUsar } })
                }
                if (plantioTalhao.filter(m => m.plantasMetro).length) {
                    addCols.push({ title: "Plantas p/ Metro", field: "plantasMetro", width: 'auto', propsField: { fontSize: fontSizeUsar } })
                }
                if (plantioTalhao.filter(m => m.espacamento).length) {
                    addCols.push({ title: "Espaçamento entre linhas(CM)", field: "espacamento", width: 'auto', propsField: { fontSize: fontSizeUsar } })
                }


                // if (manejosTalhao.filter(m => m.dosePorTanque).length) {
                //     addCols.push({ title: "Dose por Tanque", field: "dosePorTanque", width: 'auto' })
                // }
                // if (manejosTalhao.filter(m => m.volumeTanque).length) {
                //     addCols.push({ title: "Volume Tanque (L)", field: "volumeTanque", width: 'auto' })
                // }

                // if (manejosTalhao.filter(m => m.valorUnitario).length) {
                //     addCols.push({ title: "Valor Unitário", field: "valorUnitario", width: 'auto' })
                //     addCols.push({ title: "Valor Total", field: "valorTotal", width: 'auto' })
                // }


                cont.push(h.tablePanel([
                    { title: "Cultivar", getValue: r => (_.get(r, "cultivar.descricao") + " - " + _.get(r, "cultivar.unidadeMedida.sigla")), propsField: { fontSize: fontSizeUsar } },


                    { title: "Área Plantada", field: "area", width: 'auto', propsField: { fontSize: fontSizeUsar } },

                    { title: "Inicio do Plantio", field: "dataInicioPlantio", width: 'auto', type: "date", propsField: { fontSize: fontSizeUsar } },
                    { title: "Fim do Plantio", field: "dataFimPlantio", width: 'auto', type: "date", propsField: { fontSize: fontSizeUsar } },
                    { title: "Data Emergência", field: "dataEmergencia", width: 'auto', type: "date", propsField: { fontSize: fontSizeUsar } },
                    { title: "Prev. do Colheita", field: "dataPrevInicioColheita", width: 'auto', type: "date", propsField: { fontSize: fontSizeUsar } },



                    ...addCols,
                ], plantioTalhao),)

            }
        }

        content.push(
            h.fieldset("Dados de Plantio",
                cont

            )
        )


        return content;
    }
    renderColheita() {
        const h = this.helper;

        const content = []
        let plantios = _.get(this.visita, "plantios")
        if (!plantios || !plantios.length) {
            return null;
        }
        let grouped_data = _.groupBy(plantios, m => m.talhao.nome + " - Área: " + m.talhao.areaHa + " " + this.areaCultivada.unidadeArea)

        let cont = []

        for (const nomeTalhao in grouped_data) {
            if (Object.hasOwnProperty.call(grouped_data, nomeTalhao)) {
                const plantioTalhao = grouped_data[nomeTalhao];

                cont.push(h.panel([
                    h.row(h.title("Talhão:" + nomeTalhao, { fontSize: 12 })),
                ]))

                cont.push(h.tablePanel([
                    { title: "Cultivar", getValue: r => (_.get(r, "cultivar.descricao") + " - " + _.get(r, "cultivar.unidadeMedida.sigla")) },

                    { title: "Área Plantada", field: "area", width: 40 },
                    { title: "Área Colhida", field: "colheita.areaColhida", width: 40 },

                    { title: "Inicio da Colheita", field: "colheita.dataInicioColheita", width: 50, type: "date" },
                    { title: "Fim da Colheita", field: "colheita.dataFimColheita", width: 50, type: "date" },

                    { title: "Produção(" + this.areaCultivada.unidadeProducao + ")", field: "colheita.producao", width: 60, },
                    { title: "Produtividade(" + this.areaCultivada.unidadeProducao + "/" + this.areaCultivada.unidadeArea + ")", field: "colheita.produtividade", width: 100, },


                ], plantioTalhao),)

            }
        }

        content.push(
            h.fieldset("Dados de Colheita",
                cont
            )
        )


        return content;
    }
    renderParecer() {
        const h = this.helper;
        let d = h.get("dataCadastro")
        if (d.toDate) {
            d = d.toDate()
        }
        const date = h.formatDate(d);

        const content = [
            h.fieldset("Parecer Técnico", [
                h.panel([
                    h.row(h.title('Parecer Técnico')),
                    h.row(h.textField("parecerExterno"))
                ]
                )
            ]),
            h.space(),

            h.panel([
                h.row(
                    h.text("", { alignment: "center", style: null }),
                    h.text(`Data de Emissão do Laudo: ${date}`, { fontSize: 10, alignment: "right", bold: false, style: null })
                ),

                h.row(
                    h.text("________________________________________", { alignment: "center", style: null, margin: [0, 35, 0, 0] }),
                    h.text("________________________________________", { alignment: "center", style: null, margin: [0, 35, 0, 0] }),
                ),

                // prettier-ignore
                h.row(
                    h.textField("userNome", { fontSize: 10, alignment: "center", style: null }),
                    h.textField("stringProdutores", { fontSize: 10, alignment: "center", style: null }),
                ),
                h.row(
                    h.textField("userCrea", { fontSize: 9, alignment: "center", style: null }),
                    ""
                ),


            ]),
        ];

        return h.unbreakable(content);
    }
}
