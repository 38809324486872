import _ from "lodash";
import Report from "report";
import TableReportHelper from "report/helper";

export default class ResumoTalhaoReport extends Report {
    constructor({ talhao, perfil, areaCultivada, imageTalhao }) {
        super();

        this.talhao = talhao;
        this.perfilAtual = perfil;
        this.areaCultivada = areaCultivada;
        this.imageTalhao = imageTalhao;
    }

    get title() {
        return "Resumo de Talhão";
    }

    get fileName() {
        function replaceInvalidChars(fileNameToCheck) {
            var newFileName = fileNameToCheck.replace(/[^\w.-]+/g, '_');
            return newFileName;
        }
        
        if (this.areaCultivada) {
            return replaceInvalidChars('Resumo de Talhão ' + this.talhao.nome + " - " + this.areaCultivada.nomeArea + ' - ' + this.areaCultivada.safra + ' - ' + this.areaCultivada.cultura)
        } else {
            return this.title;
        }
    }
    adapterMeta(meta) {


        if (this.perfilAtual.logoEmpresa) {
            meta.logo = this.perfilAtual.logoEmpresa.foto
        }
        let planoTxt = ""
        if (!this.perfilAtual.tipo || this.perfilAtual.tipo === 'comum') {
            planoTxt = "PLANO GRATUITO - "
        }
        meta.textoRodape2 = planoTxt + 'e-Laudo - www.elaudo.agr.br'
        meta.textoCabecalho1 = "Talhão: " + this.talhao.nome
        meta.textoCabecalho2 = this.areaCultivada.nomeArea
        return meta
    }

    async render() {
        console.log('render', this.areaCultivada)
        console.log('this.talhao', this.talhao)
        this.helper = new TableReportHelper(this.areaCultivada);



        return [
            await this.renderMap(),
            await this.renderHeader(),
            await this.renderPlantios(),

        ]
    }

    async renderMap() {
        const h = this.helper;
        let img = this.imageTalhao
        const content = []
        content.push(
            h.panel([
                // h.row(h.title("Talhão", { fontSize: 12, margin: [1, 10, 1, 10], alignment: "center" }),),

                h.row(h.image(img, { alignment: 'center', fit: [500, 200], })),
            ]),


        )


        return content;
    }

    renderHeader() {
        const h = this.helper;

        const content = []


        content.push(
            h.panel([
                h.row(h.title('Produtor'), h.textField('nomeProdutor')),
            ], { widths: [75, "*"] }),
            h.panel([
                h.row(h.title('Propriedade'), h.textField('nomeArea'), h.title('Área (' + this.areaCultivada.unidadeArea + ")"), h.textField('areaHa')),
                h.row(h.title('Cultura'), h.textField('cultura'), h.title('Safra'), h.textField('safra')),
                h.row(h.title('Talhão'), h.text(this.talhao.nome), h.title('Área (' + this.areaCultivada.unidadeArea + ")"), h.text(this.talhao.areaHa)),
            ], { widths: [75, "*", 70, 80] })


        )


        return content;
    }
    renderPlantios() {
        const plantios = (this.areaCultivada?.plantios || []).filter(p => p.talhao.nome === this.talhao.nome)

        const h = this.helper;

        const content = []

        content.push(
            h.panel([
                h.row(h.title("Plantios", { fontSize: 12, margin: [1, 10, 1, 10], alignment: "center" }),),

            ]),


        )

        content.push(h.tablePanel([
            { title: 'Variedade', field: "cultivar.descricao", },
            { title: 'Área', field: "area", width: 35, type: "number" },
            { title: 'Ciclo', field: "cultivar.ciclo", width: 25, },
            {
                title: 'Início Plantio',
                getValue: (row) => {
                    let data = _.get(row, "dataInicioPlantio")
                    if (data) {
                        data = data.split("-").reverse().join("/")
                    }
                    return data;
                },
                width: 55,
            },
            {
                title: 'Fim Plantio',
                getValue: (row) => {
                    let data = _.get(row, "dataFimPlantio")
                    if (data) {
                        data = data.split("-").reverse().join("/")
                    }
                    return data;
                },
                width: 55,
            },
            // { title: 'Emergência', field: "dataEmergencia", width: 55,},
            {
                title: 'Prev. Colheita',
                getValue: (row) => {
                    let data = _.get(row, "dataPrevInicioColheita")
                    if (data) {
                        data = data.split("-").reverse().join("/")
                    }
                    return data;
                }
            },
            {
                title: 'Área Colhida',
                getValue: (row) => {
                    let value = _.get(row, "colheita.areaColhida")
                    if (!value) {
                        return 0
                    }
                    return value
                },
                width: 55, type: "number"
            },
            { title: 'Produção(' + this.areaCultivada.unidadeProducao + ")", field: "colheita.producao", width: 65, type: "number" },
            { title: '' + this.areaCultivada.unidadeProducao + "/" + this.areaCultivada.unidadeArea, field: "colheita.produtividade", width: 45, type: "number" },

        ], plantios))

        return content;
    }

}