import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { usePerfil, useUserUid } from 'useAuth';

import DefaultEntityCrud from 'components/form/DefaultEntityCrud';
import FormContent from 'components/form/FormContent';
import FormRow from 'components/form/FormRow';
import TextInput from 'components/form/input/TextInput';
import TabContainer from 'components/tabs/TabContainer';
import TabPanel from 'components/tabs/TabPanel';
import ImageInput from 'components/form/input/ImageInput';

import Perfil from 'entity/Perfil';
import PaineMembrosEquipe from 'entity/MembroEquipe/components/PaineMembrosEquipe';

import { Box, Button, TextField, Typography, useMediaQuery } from '@mui/material';

import { useActivePlan } from 'module/PurchaseControl';
import firebaseUtil from 'firebaseUtil';

import { toast } from 'react-toastify';
import { dateToLocal } from 'util/date';
import { theme } from 'AppPersonalise';
import { saveOnlyNew } from 'dbApi';
import ExclusaoDialog from './exclusao-dialog';

function MeuPerfilFormInter(props) {
    const navigate = useNavigate()
    const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [tab, setTab] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)
    const { tipo } = props.values
    const currentUser = firebaseUtil.getCurrentUser()
    const perfil = usePerfil()
    const activePlan = useActivePlan()

    const handlePlanPurchase = () => {
        navigate("/planos", { state: { backRoute: "/meuperfil" } })
    }

    const handleOpenDeleteModal = () => setModalOpen(true)

    const handleDeleteUser = async () => {
        perfil.nome = `(excluído) ${perfil.nome}`

        try {
            await currentUser.delete().then(() => {
                saveOnlyNew({ uid: perfil.uid, nome: perfil.nome, status: "inativo" }, "User")
                toast.success("Conta deletada com sucesso!", { autoClose: 1500, closeOnClick: true })
                setTimeout(() => {
                    navigate("/login")
                }, 1500)
            })
        } catch (error) {
            toast.error(error)
            console.log(error)
        }
    }

    const dateLeft = () => {
        if (!activePlan?.trialDate?.end || !activePlan?.trialDate?.start) {
            return 0
        }

        const endDate = new Date(activePlan?.trialDate.end)
        const today = new Date()
        const diffInMiliseconds = Math.abs(endDate - today)
        const diffInDays = Math.floor(diffInMiliseconds / (1000 * 60 * 60 * 24))

        return diffInDays
    }

    return (
        <FormContent>
            <TabContainer tab={tab} changeTab={setTab} hideIfOne >
                <TabPanel label="Dados">
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', pb: 2, margin: "0 auto", flexDirection: 'column', justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <div style={{ width: "100%", display: 'flex', flex: 1, padding: 5, justifyContent: "flex-start", alignItems: "flex-start" }}>
                            <ImageInput name='logoEmpresa' label='Adicionar Logo' imageField="foto" txtSeletor="" imageIcon />
                        </div>

                        <FormContent sx={{ px: 2, flex: '2 1 550px', width: "100%", }}>
                            <FormRow>
                                <TextInput name="uid" label="Nome" disabled />
                                <TextInput name="nome" label="Nome" />
                                <TextInput name="email" label="E-mail" disabled />
                            </FormRow>

                            <FormRow noWrap>
                                <TextInput name="crea" label="CREA" />
                                <TextInput name="telefone" label="Telefone" mask={"(00)[0]0000-0000"} InputLabelProps={{ shrink: true }} />
                                <TextInput name="empresa" label="Empresa" />
                            </FormRow>

                            <FormRow>
                                <TextInput name="endereco_contato" label="Endereço/Contato" />
                            </FormRow>
                            <FormRow>
                                <TextInput name="tipo" label="Tipo de Conta" disabled />
                            </FormRow>
                            {perfil?.trialDate && perfil?.trialDate.isActive &&
                                <Box border={1} borderRadius={1} p={2}>
                                    <Typography variant='h6' >
                                        Dados do Plano
                                    </Typography>
                                    <Box display="flex" flexDirection="column" gap={2}>
                                        <Box display="flex" flexDirection={smScreen ? "column" : "row"} gap={2}>
                                            <TextField label="Nome" value={`Plano trial (30 dias grátis) - ${dateLeft() <= 0 ? `expirado` : `${dateLeft() + 1} dias restantes`}`} variant='standard' fullWidth disabled={tipo !== 'admin' ? true : false} />
                                            <TextField label="início" variant='standard' value={dateToLocal(activePlan?.trialDate?.start)} disabled={tipo !== 'admin' ? true : false} />
                                            <TextField label="Fim" variant='standard' value={dateToLocal(activePlan?.trialDate?.end)} disabled={tipo !== 'admin' ? true : false} />
                                        </Box>
                                        <Box>
                                            <Button color="warning" fullWidth={smScreen} onClick={() => handlePlanPurchase()}>Contratar plano</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            }

                            {activePlan && !activePlan.trialDate &&
                                <Box border={1} borderRadius={1} p={2}>
                                    <Typography variant='h6' >
                                        Dados do Plano
                                    </Typography>
                                    <FormRow>
                                        <TextField value={activePlan.title} label="Plano Contratado" variant='standard' disabled fullWidth />
                                        <TextField value={activePlan.purchaseDate} label="Data de aquisição" variant='standard' disabled fullWidth />
                                    </FormRow>
                                    {window.cordova ? (
                                        <FormRow sx={{ display: "inline-block" }}>
                                            <Typography variant='span' color="text.secondary" fontSize={12} align='center' mt={2}>
                                                Para realizar o cancelamento do plano, acesse suas subscrições na PlayStore ou AppStore
                                            </Typography>
                                        </FormRow>
                                    ) : (
                                        <FormRow sx={{ display: "inline-block" }}>
                                            <Typography variant='span' color="text.secondary" fontSize={12} align='center' mt={2}>
                                                Para realizar o cancelamento do plano, entre em contato com o suporte {" "}
                                            </Typography>
                                            <button
                                                style={{
                                                    background: "transparent",
                                                    border: "none",
                                                    cursor: "pointer",
                                                    fontSize: 12,
                                                    fontStyle: "italic",
                                                    fontWeight: "bold",
                                                    color: "rgba(0, 0, 0, 0.6)",
                                                    padding: 0,
                                                    marginTop: 0
                                                }}
                                                onClick={() => {
                                                    window.open(
                                                        "https://api.whatsapp.com/send/?phone=556798463002&text=Olá!%20Gostaria%20de%20ajuda%20sobre%20o%20e-Laudo.",
                                                        "_blank"
                                                    );
                                                }}>clicando aqui
                                            </button>
                                        </FormRow>
                                    )}
                                </Box>
                            }
                        </FormContent >
                    </Box>
                </TabPanel>
                {['equipe', 'admin'].includes(tipo) && <TabPanel label="Equipe" sx={{ m: 1 }}>
                    <PaineMembrosEquipe />
                </TabPanel>}
                <TabPanel label="Exclusão de conta">
                    <FormContent sx={{ px: 1 }}>
                        <Typography variant='h1' sx={{ fontSize: 24, fontWeight: "bold", textAlign: "center", mt: 4 }}>Para excluir todos os seus dados, clique no botão abaixo e siga as instruções</Typography>
                        <FormRow>
                            <Button onClick={handleOpenDeleteModal} color='error' fullWidth sx={{ height: 50 }}>Excluir dados permanentemente</Button>
                            <ExclusaoDialog acaoDeletar={handleDeleteUser} handleClose={setModalOpen} open={modalOpen} />
                        </FormRow>
                    </FormContent>
                </TabPanel>
            </TabContainer>
        </FormContent >
    )
}

export default function MeuPerfilForm() {
    const uidUser = useUserUid()

    return (
        <DefaultEntityCrud
            fixedUid={uidUser || "1"}
            EntityClass={Perfil}
            title={"Meu Perfil"}
            routeForm="meuperfil"
            // backRoute="consulta-perfil"
            formActions={{ novoForm: false, salvarForm: true, sairForm: true }}
        >
            {(props) => {
                return <MeuPerfilFormInter {...props} />
            }}
        </DefaultEntityCrud>
    )
}