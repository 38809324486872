import React, { useEffect, useState } from 'react'

import DefaultEntityCrud from 'components/form/DefaultEntityCrud';
import FormContent from 'components/form/FormContent';
import FormRow from 'components/form/FormRow';


import TextInput from 'components/form/input/TextInput';

import CheckboxInput from 'components/form/input/CheckboxInput';
import TabContainer from 'components/tabs/TabContainer';
import TabPanel from 'components/tabs/TabPanel';
import AreaCultivada from 'entity/AreaCultivada';
import AutocompleteInput from 'components/form/input/AutocompleteInput';
import { usePerfil } from 'useAuth';
import { fetchArray } from 'entity';
import TabelaTalhoes from './components/TabelaTalhoes';
import MapPoligons from '../../../../components/MapPoligons';
import { poligonStr2List } from '../../../../components/MapPoligons/UtilCalcCordenadas';
import LaudosAreaCultivada from './components/LaudosDaArea';
import LaudoVisita from 'entity/LaudoVisita';
import { Box, Button, Modal, Typography, useMediaQuery } from '@mui/material';

import Cultura from 'entity/Cultura';
import { useTheme } from '@emotion/react';
import PainelMembrosPermitidos from './components/PainelMembrosPermitidos';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export function AreaCultivadaFormInter(props) {
    const [laudos, setLaudos] = useState([])
    const [possivelSafras, setPossivelSafras] = useState([])
    const [possivelCulturas, setPossivelCulturas] = useState([])
    const [culturasCadastradas, setCulturasCadastradas] = useState([])
    const [poligonosTalhoes, setPoligonosTalhoes] = useState([])
    const [tab, setTab] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)
    const [typedDelete, setTypedDelete] = useState(true)

    const { uid, talhoes, cultura } = props.values

    const theme = useTheme()
    const navigate = useNavigate()
    let mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleOpenDeleteModal = () => setModalOpen(true)
    const handleCloseDeleteModal = () => setModalOpen(false)

    const deleteAreaCultivada = (uid) => {
        if (props.values.delete.toLowerCase() === "deletar") {
            try {
                AreaCultivada.delete(uid).then(() => {
                    toast.success("Área Cultivada excluída!")
                    handleCloseDeleteModal()
                    navigate("/consulta-areaCultivada")
                })
            } catch (error) {
                toast.error("Oops, ocorreu algum erro no processo. Tente novamente")
            }
        }
    }

    const handleNewArea = () => {
        let newV = {
            cultura: props.values.cultura,
            nomeArea: props.values.nomeArea,
            nomeProdutor: props.values.nomeProdutor,
            talhoes: props.values.talhoes,
            unidadeArea: props.values.unidadeArea,
            unidadeProducao: props.values.unidadeProducao,
            ativo: true,
        }

        navigate(`/areaCultivada`)
        setTab(0)

        setTimeout(() => {
            props.form.initialize(newV)
        }, 500)


    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        maxWidth: "400px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        if (props.values.delete) {
            if (props.values.delete.toLowerCase() === "deletar") {
                setTypedDelete(false)
            } else {
                setTypedDelete(true)
            }
        }
    }, [props.values.delete, props.values.nome])

    useEffect(() => {
        if (talhoes) {
            let ou = [];
            let areaHa = 0
            talhoes.forEach(t => {
                areaHa += parseFloat(t.areaHa)

                if (t.areaNoMapStr) {

                    ou.push({
                        title: t.nome,
                        props: { "Área": t.areaHa, "Cultura": cultura },
                        path: poligonStr2List(t.areaNoMapStr)
                    })
                }

            }
            );
            setPoligonosTalhoes(ou)
            props.form.change("areaHa", areaHa.toFixed(2))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cultura, talhoes])

    const perfil = usePerfil();
    useEffect(() => {

        Cultura.query().then(query => {
            fetchArray(query.get()).then(result => {

                setCulturasCadastradas([...new Set(result.map(a => a.nome.toUpperCase()))])

            })
        })




    }, [])
    useEffect(() => {
        if (perfil) {

            AreaCultivada.query().then(query => {
                query = query.where("usuariosConfirmados." + perfil.uid, "==", true)
                fetchArray(query.get()).then(result => {
                    setPossivelSafras([...new Set(result.map(a => a.safra))])
                    setPossivelCulturas([...new Set(result.map(a => a.cultura?.toUpperCase()))])
                })
            })



        }
    }, [perfil])
    useEffect(() => {
        if (uid) {
            LaudoVisita.query().then(query => {
                query = query.where("areasCultivadasKeys." + uid, "==", true)
                fetchArray(query.get()).then(result => {
                    result.sort((a, b) => a.dataCadastro < b.dataCadastro ? 1 : -1)
                    setLaudos(result)

                })
            })
        }
    }, [uid])

    return <FormContent sx={{}}>
        <TabContainer tab={tab} changeTab={setTab} sx={{ flex: 1, display: "flex", flexDirection: 'column' }}>
            <TabPanel label="Dados" >
                <FormContent sx={{ m: 1 }}>
                    <FormRow noWrap>
                        <AutocompleteInput
                            freeSolo
                            name="safra" label="Safra"
                            options={possivelSafras}
                            getOptionLabel={(option) => option || ""}
                            required
                        // disabled={uid ? true : false}
                        />
                        <AutocompleteInput
                            freeSolo
                            formatText={v => v.toUpperCase()}
                            name="cultura" label="Cultura"
                            getOptionLabel={(option) => option || ""}

                            // options={possivelCulturas}
                            options={[...new Set([...possivelCulturas, ...culturasCadastradas])]}
                            required
                        />

                        {/* <SeletorCultura listaOutraCulturasNomes={possivelCulturas} /> */}
                    </FormRow>
                    <FormRow>
                        <TextInput name="nomeArea" label="Nome da Área Cultivada" required />
                    </FormRow>

                    <FormRow>
                        <TextInput name="nomeProdutor" label="Nome Produtor" required />
                    </FormRow>

                    <FormRow noWrap>
                        <TextInput name="unidadeArea" label="Unidade de Área" required />
                        <TextInput name="unidadeProducao" label="Unidade de Produção" required />
                        <TextInput name="areaHa" label="Área Total" disabled />
                    </FormRow>
                    <FormRow noWrap>
                        <CheckboxInput name="ativo" label="Área Ativa" />
                        {/* <CheckboxInput name="fechado" label="Área Fechada" /> */}
                    </FormRow>

                    <TabelaTalhoes areaCultivada={props.values} />

                </FormContent >
            </TabPanel>
            <TabPanel label="Mapa" sx={{ m: 1, flex: 1, display: "flex" }}>
                <MapPoligons
                    outrosPoligonos={poligonosTalhoes}
                    pontos={laudos.filter(l => l.pontoGeoInicial).map(l => {

                        let position = l.pontoGeoInicial;
                        if (position.longitude) {
                            position = { lat: position.latitude, lng: position.longitude }
                        }
                        return {
                            title: "Nº " + l.sequencialIndex,
                            position: position,
                            props: {
                                Data: l.dataLastChangeStr,
                                Técnico: l.userNome
                            }
                        }
                    })}
                />
            </TabPanel>
            <TabPanel label={mobile ? "Laudos" : "Laudos de Visita"} sx={{ m: 1, flex: 1, display: "flex" }}>
                <LaudosAreaCultivada laudos={laudos} areaCultivada={props.values} />
            </TabPanel>
            <TabPanel label={mobile ? "Conf." : "Configurações"} sx={{ m: 1, flex: 1, display: "flex", flexDirection: "column" }}>
                <Box p={1} mb={2} gap={2} border={1} borderRadius={2} borderColor="#EAEAEA" display="flex" flexDirection="column">
                    <Typography variant='h6'>Ações para a Área Cultivada</Typography>
                    <Box display="flex" gap={1}>
                        <Button color='warning' onClick={handleNewArea} sx={{ maxWidth: "150px" }}>Duplicar área</Button>
                        {props.values.uid && <Button color='error' onClick={() => handleOpenDeleteModal(true)}>Deletar</Button>}
                    </Box>
                </Box>
                <PainelMembrosPermitidos />
                <Box>
                    <Button onClick={() => console.log('values', props.values)}>Console</Button>
                </Box>
            </TabPanel>
        </TabContainer>

        <Modal
            open={modalOpen}
            onClose={handleCloseDeleteModal}
        >
            <Box sx={style} display="flex" flexDirection="column" gap={2}>
                <Typography variant='h6' component='h2'>Excluir registros</Typography>
                <Typography>
                    Esta ação excluirá permanentemente este registro. O registro removido NÃO PODE ser restaurado! Tem certeza ABSOLUTA?
                </Typography>
                <Typography>
                    Esta ação pode levar à perda de dados. Para evitar ações acidentais, solicitamos que você confirme sua intenção.
                </Typography>
                <form>
                    <FormContent>
                        <FormRow>
                            <TextInput label={'Digite "deletar" para confirmar a exclusão'} name="delete" initialValue="" required />
                        </FormRow>
                        <FormRow>
                            <Button color='primary' onClick={handleCloseDeleteModal}>Cancelar</Button>
                            <Button color='error' disabled={typedDelete} onClick={() => deleteAreaCultivada(props.values.uid)}>Excluir definitivamente</Button>
                        </FormRow>
                    </FormContent>
                </form>
            </Box>
        </Modal>

    </FormContent >
}

export default function AreaCultivadaForm() {
    return (
        <DefaultEntityCrud
            EntityClass={AreaCultivada}
            title={"Área Cultivada"}
            routeForm="areaCultivada"
            backRoute="consulta-areaCultivada"
            formActions={{ novoForm: true, salvarForm: true, sairForm: true }}
        >
            {(props) => {
                return <AreaCultivadaFormInter {...props} />
            }}
        </DefaultEntityCrud>
    )
}