import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const startApp = () => {

  ReactDOM.render(
    <React.Fragment>
      <App />
    </React.Fragment>,
    document.getElementById('root')
  );
};


if (window.cordova) {
  if (window.cordova.InAppBrowser)
    window.open = window.cordova.InAppBrowser.open;
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}

