import { AddPhotoAlternateOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { Box, Button, ButtonGroup, Typography, useMediaQuery } from "@mui/material";
import { theme } from "AppPersonalise";
import ImageBox from "components/basic/ImageBox";
import { showDialog } from "components/dialog";
import ConfirmDialog from "components/dialog/ConfirmDialog";
import WindowDialog from "components/dialog/WindowDialog";
import _ from "lodash";
import { useCallback, useState } from "react";
import { rotateImage, scaleImage } from "util/image";
import FormContent from "../FormContent";
import { useCoordinates } from "./GeolocationButton";
import InputBox from "./InputBox";
import { NestedField, PField, useFieldValue } from "./NestedField";

/**
 * Input para upload de imagens. As imagen são redimensionadas para maxWidth x maxHeight.
 */
export default function ImageInput({
    name,
    label,
    txtSeletor,
    imageField = "base64",
    multiple = false,
    geolocationField,
    maxWidth = 480,
    maxHeight = 480,
    quality = 0.7,
    format = "image/jpeg",
    small,
    onRemove,
    sx,
    children,
    imageIcon,
    ...props
}) {
    const [coordinates] = useCoordinates();
    const [fileInput, setFileInput] = useState();
    const [deleteDialog, setDeleteDialog] = useState();
    const [dialogMobile, setDialogMobile] = useState();
    const mobile = window.cordova ? true : false
    const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

    let [value, setValue] = useFieldValue(name);

    const fileInputRef = useCallback((node) => {
        if (node) {
            setFileInput(node);
        }
    }, []);

    async function handleFileChangeMobile(dataImage) {
        let src = "data:image/jpeg;base64," + dataImage

        async function setImage(obj, image) {
            _.set(obj, imageField, await scaleImage(image, maxWidth, maxHeight, format, quality));
            if (geolocationField) {
                if (_.get(obj, geolocationField)) {
                    _.set(obj, geolocationField, coordinates);
                }
            }
        }

        let newFiles = [];

        if (multiple) {
            const item = {};
            await setImage(item, src);
            newFiles.push(item);
            setValue([...value, ...newFiles]);
        } else {
            newFiles = {
                ...value,
            };
            await setImage(newFiles, src);
            setValue(newFiles);
        }

        if (dialogMobile) {
            dialogMobile.setOpen(false);
        }
    }

    async function onFileSelected(event) {
        if (event.target.files.length > 0) {

            let newFiles = [...value];

            if (multiple) {
                for (let file of event.target.files) {
                    newFiles.push({
                        [imageField]: await scaleImage(file, maxWidth, maxHeight, format, quality),
                        galeria: true
                    });
                }
            } else {
                newFiles = (
                    {
                        [imageField]: await scaleImage(event.target.files[0], maxWidth, maxHeight, format, quality),
                        galeria: true
                    }
                );
            }
            setValue(newFiles);
        }
    }

    function selectFiles() {
        if (mobile) {
            dialogMobile.setOpen(true);
        } else {
            if (fileInput) {
                fileInput.click();
            }
        }
    }

    function remove(index) {
        showDialog(deleteDialog).then((confirm) => {
            if (confirm) {
                let newFiles;
                let removed;

                if (multiple) {
                    newFiles = [...value];
                    removed = newFiles.splice(index, 1)[0];
                } else {
                    removed = value;
                    newFiles = null;
                }

                setValue(newFiles);

                if (onRemove) {
                    onRemove(removed, index);
                }
            }
        });
    }

    async function rotate(index, degrees) {
        let newFiles;

        if (multiple) {
            newFiles = [...value];
            let newv = await rotateImage(getBase64Value(newFiles[index]), degrees)
            setBase64Value(newFiles[index], newv)
        } else {
            newFiles = _.cloneDeep(value);
            let newBase = await rotateImage(getBase64Value(newFiles), degrees)
            newFiles = setBase64Value(newFiles, newBase)
        }

        setValue(newFiles);
    }

    let valueArray = [];

    if (_.isArray(value)) {
        valueArray = value;
    } else if (value) {
        valueArray = [value];
    }

    const getBase64Value = (item) => {
        if (imageField) {
            return _.get(item, imageField)
        } else {
            return item
        }
    }
    const setBase64Value = (item, base) => {
        if (imageField) {
            _.set(item, imageField, base)
        } else {
            item = base
        }
        return item
    }

    return (
        <InputBox sx={sx}>
            <PField name={name}>
                {(fieldProps) => (
                    <>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
                            <Box sx={{ display: "flex", alignItems: smScreen ? "center" : "flex-start", flexDirection: "column", width: "100%" }}>
                                <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center" }}>

                                    {(label && !value) && <Typography sx={{ flex: 1 }} variant='h6'>{label}</Typography>}

                                    {(imageIcon && (!multiple && !value)) && (
                                        <AddPhotoAlternateOutlined onClick={selectFiles} sx={{ color: "primary.main", fontSize: "150px", cursor: "pointer", textAlign: "start" }} />
                                    )}

                                    {/* Oculta botão adicionar em modo pequeno quando já houver uma imagem. */}
                                    {small && !multiple && value ? null : (
                                        <Box sx={{ display: (value && !multiple) ? "none" : "flex" }}>
                                            <Button
                                                startIcon={small ? null : <AddIcon />}
                                                size={small ? "small" : "medium"}
                                                onClick={selectFiles}
                                                title="Adicionar Imagem"
                                            >
                                                {small ? <AddIcon /> : txtSeletor ? txtSeletor : multiple ? "Adicionar Imagens" : "Selecionar Imagem"}
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            {valueArray.map((valueFile, index) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: smScreen ? "center" : "flex-start",
                                        flexDirection: { xs: "column", sm: "row" },
                                        gap: 2,
                                        pb: multiple ? 2 : 0,
                                        borderBottom: "1px dotted",
                                        borderColor: "primary.main",
                                        "&:last-child": { borderBottom: 0 },
                                    }}
                                    key={index}
                                >
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: small ? 0.5 : 2 }}>
                                        {getBase64Value(valueFile) ? (
                                            <>
                                                <ImageBox
                                                    sx={{ width: small ? null : { xs: "100%", sm: "200px" } }}
                                                    maxWidth={small ? 120 : null}
                                                    maxHeight={small ? 120 : null}
                                                    src={getBase64Value(valueFile)}
                                                />
                                            </>
                                        ) : (
                                            <Typography
                                                variant="caption"
                                                sx={{ width: { xs: "100%", sm: "200px", textAlign: "center" } }}
                                            >
                                                Imagem não carregada
                                            </Typography>
                                        )}
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <ButtonGroup variant="outlined" size={small ? "small" : "medium"}>
                                                <Button onClick={() => rotate(index, -90)}>
                                                    <RotateLeftIcon fontSize={small ? "small" : "medium"} />
                                                </Button>
                                                <Button onClick={() => remove(index)}>
                                                    <DeleteForeverIcon fontSize={small ? "small" : "medium"} />
                                                </Button>
                                                <Button onClick={() => rotate(index, 90)}>
                                                    <RotateRightIcon fontSize={small ? "small" : "medium"} />
                                                </Button>
                                            </ButtonGroup>
                                        </Box>
                                    </Box>
                                    {children ? (
                                        <NestedField prefix={name} index={index}>
                                            <FormContent>{children}</FormContent>
                                        </NestedField>
                                    ) : null}
                                </Box>
                            ))}
                        </Box>
                        <input
                            ref={fileInputRef}
                            hidden
                            type="file"
                            multiple={multiple}
                            onChange={(event) => onFileSelected(event)}
                        />
                    </>
                )}
            </PField>

            <ConfirmDialog title="Remover" setDialog={setDeleteDialog}>
                Deseja realmente remover esta foto?
            </ConfirmDialog>
            <WindowDialog title={"Tipo de Imagem"} setDialog={setDialogMobile}
                contentProps={{ sx: { p: 2, flexDirection: "column", gap: 1 } }}
                notFullScreen {...props}>
                {(dialogProps) => {
                    return (
                        <>
                            <Button onClick={() => {
                                const openGalery = () => {
                                    return new Promise((resolve, reject) => {
                                        navigator.camera.getPicture(resolve, reject, {
                                            quality: 30,
                                            // eslint-disable-next-line no-undef
                                            destinationType: Camera.DestinationType.DATA_URL,
                                            sourceType: navigator.camera.PictureSourceType.SAVEDPHOTOALBUM
                                        });
                                    })
                                }
                                const handleOpenGalery = async () => {
                                    try {
                                        const dataImage = await openGalery();
                                        await handleFileChangeMobile(dataImage);
                                    } catch (error) {
                                        console.error("Erro ao capturar ou processar a imagem ", error);
                                    }
                                };
                                handleOpenGalery()
                            }}>
                                Galeria
                            </Button>
                            <Button onClick={() => {
                                const takePhoto = () => {
                                    return new Promise((resolve, reject) => {
                                        navigator.camera.getPicture(resolve, reject, {
                                            quality: 30,
                                            correctOrientation: true,
                                            // eslint-disable-next-line no-undef
                                            destinationType: Camera.DestinationType.DATA_URL,
                                        });
                                    })
                                }
                                const handleTakePhoto = async () => {
                                    try {
                                        const dataImage = await takePhoto();
                                        await handleFileChangeMobile(dataImage);
                                    } catch (error) {
                                        console.error("Erro ao capturar ou processar a imagem ", error);
                                    }
                                };
                                handleTakePhoto()
                            }}>
                                Câmera
                            </Button>
                        </>
                    )
                }}
            </WindowDialog>
        </InputBox>
    );
}
